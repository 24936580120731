import React, {Component} from 'react';
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';
import {formatDate} from "../utils/UtilityFunctions";

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    section: {
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        paddingTop: 10,
        paddingBottom: 10,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    col: {
        width: '33%',
    },
    colRight: {
        width: '33%',
        textAlign: 'right',
    },
    title: {
        fontSize: 16,
        marginBottom: 10,
    },
    text: {
        fontSize: 14,
        fontWeight: 300,
        marginBottom: 10,
    }
});

// Create Document Component
class MyDocument extends Component {
    
    renderProducts(order) {
        return order.products.map((product) => {
            return (
                <View style={styles.row}>
                    <View style={styles.col}>
                        <Text style={styles.title}>{product.name}</Text>
                    </View>
                    <View style={styles.colRight}>
                        <Text style={styles.text}>{product.qty.toString()}</Text>
                    </View>
                    <View style={styles.colRight}>
                        <Text style={styles.text}>{product.price.toString()}</Text>
                    </View>
                </View>
            )
        })
    }

    render() {
        const order = this.props.order;
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section} render={() => (
                        <Text style={styles.title}>{`Order Date: ${formatDate(order.orderdate)}`}</Text>
                    )}>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.text} render={() => (`Order Number: ${order.id}`)}/>
                        <Text
                            style={styles.text}
                            render={() => (`Status: ${order.status} on ${formatDate(order.deliverdate)}`)}/>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.row}>
                            <View style={styles.col}>
                                <Text style={styles.text}>Product</Text>
                            </View>
                            <View style={styles.colRight}>
                                <Text style={styles.text}>Qty</Text>
                            </View>
                            <View style={styles.colRight}>
                                <Text style={styles.text}>Price</Text>
                            </View>
                        </View>
                    </View>
                    
                    <View style={styles.section} render={() => ( this.renderProducts(order)) } />
                    
                    <View style={styles.section}>
                        <View style={styles.row}>
                            <View style={styles.colRight}>
                                <Text style={styles.text}>Subtotal</Text>
                            </View>
                            <View style={styles.colRight}>
                                <Text style={styles.text} render={() => (order.subtotal.toFixed(2))}/>
                            </View>
                        </View>
                        <View render={() => {
                            if (order.discount !== 0) {
                                return (
                                    <View style={styles.row}>
                                        <View style={styles.colRight}>
                                            <Text style={styles.text}>Discount</Text>
                                        </View>
                                        <View style={styles.colRight}>
                                            <Text style={styles.text} render={() => (`- ${order.discount.toFixed(2)}`)}/>
                                        </View>
                                    </View>
                                )
                            }
                        }}>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.colRight}>
                                <Text style={styles.text}>Shipping</Text>
                            </View>
                            <View style={styles.colRight}>
                                <Text style={styles.text} render={() => (`+ ${order.shipping.toFixed(2)}`)}/>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.colRight}>
                                <Text style={styles.title}>Total</Text>
                            </View>
                            <View style={styles.colRight}>
                                <Text style={styles.title} render={() => (order.total.toFixed(2))}/>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
}

export default MyDocument;
