import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {inject, observer} from "mobx-react";

@inject('appStore', 'seedStore')
@observer
class TooFew extends React.Component {
    toggle() {
        this.props.appStore.toggleTooFew();
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.appStore.tooFew} toggle={this.toggle.bind(this)} className={this.props.className}>
                    <ModalHeader toggle={this.toggle.bind(this)}></ModalHeader>
                    <ModalBody className="px-5 py-4">
                        <div className="Text-robo-med mb-4">Hold On!</div>
                        You have only filled {this.props.seedStore.filledSeeds} out of {this.props.seedStore.mySize.qty} seeds. Select more seeds to get the most out of your subscription!
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle.bind(this)}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default TooFew;
