import React from 'react';
import { Tooltip } from 'reactstrap';

export default class Tip extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    
    render() {
        return (
            <div>
                <div id="SkipShipmentButton">{this.props.button}</div>
                <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target="SkipShipmentButton" toggle={this.toggle}>
                    Modify until {this.props.modifyUntil}
                </Tooltip>
            </div>
        );
    }
}
