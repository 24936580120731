import React, { Component } from 'react';
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {ButtonGrey} from "../../../components/Buttons";

@inject('seedStore', 'userStore')
@withRouter
@observer
class ChooseAmount extends Component {
    
    componentWillMount() {
        this.props.seedStore.getSizes();
        this.props.userStore.getUser();
    }
    
    onClick(pack) {
        this.props.seedStore.onChangeSize("mySize",{value: pack.id, label: pack.name}, this.props.history);
    }
    
    doNothing() {
        // Do nothing
    }

    renderPackages = () => {
        if(this.props.seedStore.isLoading) {
            return false
        }
        
        return (
            this.props.seedStore.sizes.map((pack, i)=> {
                return (
                    <div 
                        key={i} className="ChooseAmount-package-div position-relative" onClick={() => this.onClick(pack)}>
                        <div className="ChooseAmount-package-upper-div">
                            <div className="ChooseAmount-circle-div">
                                <p className="Text-large-white">{`${pack.qty}`}</p>
                                <p className="Text-white-robo-reg">PACK</p>
                            </div>
                            <p className="Text-robo-med">{`${pack.qty} Gropod Pack`}</p>
                        </div>
                        <div className="ChooseAmount-package-lower-div">
                            <p className="ChooseAmount-description-text">{`${pack.description}`}</p>
                            <div className="ChooseAmount-savings-div">
                                <p className="Text-black-robo ChooseAmount-price-text">{`$${pack.price}`}</p>
                                {(pack.savings !== 0 && pack.savings !== null) && 
                                    <p className="Text-robo-reg-blue">{`${pack.savings}% Savings`}</p>
                                }
                            </div>
                            <ButtonGrey
                                label="Select"
                                onClick={() => this.doNothing()}
                            />
                        </div>
                        {pack.type === "bestvalue" && <div className="diagonal-ribbon ribbon-pink">Best Value</div>}
                        {pack.type === "popular" && <div className="diagonal-ribbon ribbon-blue">Most Popular</div>}
                    </div>
                )
            })
        )};

    render() {
        return (
            <div>
                <div className="Account-title-div" style={{marginBottom: 43}}>
                    <h2 className="Text-title-large">Get <span className="Text-robo-900-40px-lime">Gropod</span> cartridges delivered to your door</h2>
                    <h3 className="Text-subtitle">Customize your subscription of pesticide-free, non-GMO seeds; select up to 60 plants of your choosing.</h3>
                </div>
                <div className="ChooseAmount-contents m-auto">
                    {this.renderPackages()}
                </div>
            </div>
        );
    }
}

export default ChooseAmount;
