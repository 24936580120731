import React, {Component} from 'react';
import TextInput from "../TextInput2";
import HeaderRow, {Title} from "../../HeaderRow";
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class Contact extends Component {
    componentWillMount() {
        if(this.props.clearForm) {
            this.props.formStore.clearForm();
        }
    }

    renderHeader() {
        return (
            <HeaderRow className={"align-items-end pb-1"}>
                <Title text="Contact Information"/>
            </HeaderRow>
        )
    }

    render() {
        const errors = this.props.formStore.errors;
        return (
            <div>
                {!this.props.noHeader && this.renderHeader()}
                <div className="row">
                    <TextInput name="firstName" placeholder="First Name" errors={errors.first}/>
                    <TextInput name="lastName" placeholder="Last Name" errors={errors.last}/>
                </div>
                <div className="row">
                    <TextInput name="email" placeholder="Email" errors={errors.email}/>
                    <TextInput name="phone" placeholder="Phone" mask="(999) 999-9999" errors={errors.phone}/>
                </div>
            </div>
        );
    }
}

export default Contact;
