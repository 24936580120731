import React, { Component } from 'react';
import Select from 'react-select';
import {inject, observer} from "mobx-react";

@inject('formStore', 'dropdownStore')
@observer
class DropdownSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholder: this.props.placeholder,
        }
    }

    onMenuOpen() {
        this.setState({placeholder: "Search..."})
    }

    onMenuClose() {
        this.setState({placeholder: this.props.placeholder || ""})
    }
    
    onChange(name, input) {
        if(this.props.onChange) {
            this.props.onChange(name, input);
        } else {
            this.props.formStore.onChangeDropdown(name, input);
        }
    }

    render() {
        const options = this.props.dropdownStore[this.props.optionsKey] || this.props.dropdownStore.regionOptions;
        
        const selectedValue = this.props.value ? this.props.value : this.props.formStore.form[this.props.name] ? this.props.formStore.form[this.props.name] : options[0];

        return (
            <div className={this.props.size || "col-12 col-md-6"}>
                <div className={`form-select + ${this.props.className}`}>
                    <Select
                        isClearable={this.props.isClearable || false}
                        isDisabled={this.props.isDisabled || false}
                        value={selectedValue}
                        onChange={this.onChange.bind(this, this.props.name)}
                        options={options}
                        isSearchable={true}
                        onMenuOpen={this.onMenuOpen.bind(this)}
                        onMenuClose={this.onMenuClose.bind(this)}
                        className = "react-select-container"
                        classNamePrefix="react-select"
                        placeholder = {this.state.placeholder}
                        maxMenuHeight={160}
                        menuPlacement = {this.props.menuPlacement}
                    />
                </div>
            </div>
        );
    }
}

export default DropdownSelect;
