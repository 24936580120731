export default function appFetch(url, data, method, isFileUpload) {
    const headers = {
        "Accept": "application/json, application/xml, text/plain, text/html, *.*",
        'Content-Type': 'application/json',
        // 'X-Requested-With': 'XMLHttpRequest',
        // 'X-CSRF-TOKEN': document.head.querySelector("[name=csrf-token]").content,
    };
    let token = localStorage.getItem('token');
    if (token !== null) {
        console.log(headers);
        headers["Authorization"] = token;
    }
    let body = null;
    if (isFileUpload) {
        delete headers['Content-Type'];

        body = new FormData();
        for (const key in data) {
            body.append(key, data[key]);
        }

    } else {
        body = JSON.stringify(data);
    }
    
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    return fetch(`${baseUrl}/${url}`, {
        method: method ? method : 'GET',
        cache: 'no-cache',
        headers: headers,
        credentials: 'include',
        body: body,
    }).then(response => {
        return new Promise(resolve => {
            response.json().then(returnData => {
                returnData.httpStatus = response.status;

                resolve(returnData);
            }).catch(error => {
                error.httpStatus = response.status;

                resolve(error);
            });
        });
    });
}
