import React, {Component} from 'react';
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class Radio extends Component {
    render() {
        return (
            <input
                name={this.props.name}
                type="radio"
                defaultChecked={this.props.checked}
                onChange={(input) => this.props.formStore.onChangeRadio(input)}
                id={this.props.id}
            />
        );
    }
}

export default Radio;
