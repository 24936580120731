import React, {Component} from 'react';
import {ButtonWhite} from "../../../components/Buttons";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import EmptyMessage from "../../../components/EmptyMessage";

@inject('subscriptionStore')
@withRouter
@observer
class SubscriptionSuccess extends Component {

    render() {
        return (
            <EmptyMessage message="Thank you!" className="Text-title-large-green mt-4">
                <div className="mt-4">Your order is being processed. Visit your account to view order details.</div>
                <ButtonWhite
                    className="mx-auto mt-3"
                    label="Order Details"
                    onClick={() => this.props.history.push('/myaccount/orderhistory')}
                />
            </EmptyMessage>
        );
    }
}

export default SubscriptionSuccess;
