import React, { Component } from "react";
import {inject, observer} from "mobx-react";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./screens/Home";
import Seedpods from "./screens/SeedPods";
import MyAccount from "./screens/MyAccount";
import ShoppingCart from "./screens/ShoppingCart";
import Checkout from "./screens/Checkout";
import Register from "./screens/Register";
import Login from "./screens/Login";
import Support from "./screens/Support";
import ForgotPassword from "./screens/ForgotPassword/ForgotPassword";
import ResetPassword from "./screens/ForgotPassword/ResetPassword";
import ScrollToTop from "./components/ScrollToTop";
import AlertMessages from "./components/AlertMessages";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FooterPanel from "./components/FooterPanel";
import "./App.scss";

@inject('subscriptionStore', "userStore", "appStore", "seedStore", "cartStore")
@observer
class App extends Component {
    
    componentWillMount() {
        this.props.userStore.getUser();
        this.props.cartStore.getCart();
        this.props.seedStore.getSeeds();
        this.props.subscriptionStore.getMySubscription();
    }

    componentDidMount() {
        this.props.appStore.updateWindowDimensions();
        window.addEventListener("resize", this.props.appStore.updateWindowDimensions());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.props.appStore.updateWindowDimensions());
    }

    render() {
        return (
            <div>
                <Router>
                    <ScrollToTop>
                        <div className="Content-container">
                            <Navbar/>
                            <div className="App-body">
                                <AlertMessages/>
                                <Switch>
                                    <Route exact path="/" component={ Home } />
                                    <Route path="/gropods" component={ Seedpods } />
                                    <Route path="/support" component={ Support } />
                                    <Route path="/forgot-password" component={ ForgotPassword } />
                                    <Route path="/reset-password" component={ ResetPassword } />
                                    <Route path="/register" component={ Register } />
                                    <Route path="/login" component={ Login } />
                                    <PrivateRoute path="/myaccount" component={ MyAccount } />
                                    <Route path="/shoppingcart" component={ ShoppingCart } />
                                    <Route path="/checkout" component={ Checkout } />
                                    <Route path="*"><Redirect to="/" /></Route>
                                </Switch>
                            </div>
                        </div>
                        <Footer user={this.props.userStore.authUser}/>
                        <FooterPanel/>
                    </ScrollToTop>
                </Router>
            </div>
        );
    }
}

export default App;
