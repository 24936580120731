import React, {Component} from 'react';
import {ButtonWhite} from "../../../components/Buttons";
import {withRouter} from "react-router-dom";
import {formatDate} from "../../../utils/UtilityFunctions";
import {inject, observer} from "mobx-react";
import EmptyMessage from "../../../components/EmptyMessage";

@inject('formStore')
@withRouter
@observer
class SubscriptionSuccess extends Component {
    
    render() {
        return (
            <EmptyMessage message="Thank you for your order!" className="Text-title-large-green mt-4">
                <div className="mt-4">Your GroPod© Smart Garden Appliance will make growing your G-Pod™ Seed Cartridges simple with our advanced GroAI™ software that automates the entire growing process!</div>
                <div className="mt-4">{`Next shipment on ${formatDate(this.props.formStore.form.effectiveDate)}`}</div>
                <ButtonWhite
                    className="mx-auto mt-3"
                    label="View Subscription"
                    onClick={() => this.props.history.push('/myaccount/subscription')}
                />
            </EmptyMessage>
        );
    }
}

export default SubscriptionSuccess;
