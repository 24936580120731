import React, {Component} from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement, injectStripe} from 'react-stripe-elements';
import TextInput from "../TextInput2";
import {inject, observer} from "mobx-react";
import Radio from "../Radio";
import Check from "../Checkbox";
const visaImg = require("../../../assets/visa.png");

@inject('formStore', 'userStore', 'alertStore')
@observer
class StripeForm extends Component {

    showCardImg(savedCard) {
        if(savedCard && savedCard.type === "Visa") {
            return <img src={visaImg} alt="Visa Card" height="30px" className="ml-3"/>
        }
    }

    showCardForm() {
        const errors = this.props.formStore.errors;
        if(!this.props.formStore.useSavedToken) {
            return (
                <div className="py-3">
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                            <CardNumberElement style={{base: style}} />
                        </div>
                        <TextInput name="nameOnCard" placeholder="Name on Card" errors={errors.nameOnCard} size="col-12 col-lg-6"/>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                            <CardExpiryElement style={{base: style}}/>
                        </div>
                        <div className="col-6 col-lg-3">
                            <CardCvcElement style={{base: style}}/>
                        </div>
                        <TextInput name="paymentZip" placeholder="Zip" errors={errors.zip} size="col-6 col-lg-3"/>
                    </div>
                    { this.props.updatePayment &&
                        <div>
                            <Check
                                name="updateStoredPayment"
                                checked={this.props.formStore.form.updateStoredPayment}
                                onChange={(input) => this.props.formStore.onChangeInput(input)}
                            />
                            <span className="pl-3">Update stored payment method</span>
                        </div>
                    }
                </div>
            )
        }
    }

    render() {
        const savedCard = this.props.userStore.authUser.savedPayment || null;
        console.log('savedCard', savedCard)
        
        return (
            <div className="checkout">
                { savedCard &&
                    <div className="Cart-billing-row border-bottom-0 bg-light rounded-lg p-4 mb-2">
                        <div className="Cart-billing-row-title">
                            <Radio id="saved" name="useSavedToken" defaultChecked={true} checked={this.props.formStore.useSavedToken} />
                            <span className="Text-standard-font-small font-weight-bold pl-4">Use Saved Card</span>
                        </div>
                        <div className="Cart-billing-row-info">
                            <div className="Account-card-row Account-view-right-item">
                                <p>{`${savedCard? '**** **** **** ' + savedCard.last4: "None"}`}</p>
                                {this.showCardImg(savedCard)}
                            </div>
                        </div>
                    </div> 
                }
                <div className="bg-light rounded-lg p-4">
                    <div className="Cart-billing-row-title">
                        <Radio id="new" name="useSavedToken" defaultChecked={false} checked={!this.props.formStore.useSavedToken}/>
                        <span className="Text-standard-font-small font-weight-bold pl-4">Add New Card</span>
                    </div>
                    {this.showCardForm()}
                </div>
            </div>
        );
    }
}

export default injectStripe(StripeForm);

const style = {
    fontSize: '16px',
    fontWeight: 400,
    color: '#495057',
    fontFamily: "Open Sans"
};
