import React, {Component} from 'react';
import {matchPath, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";

@inject('seedStore', 'cartStore', 'appStore')
@withRouter
@observer
class NavTabs extends Component {

    componentWillMount() {
        if (this.matchExact(this.props.location.pathname, this.props.tab1.route)) {
            this.props[this.props.store].setActiveTab(1);
        } else if (!!matchPath(this.props.location.pathname, this.props.tab2.route)) {
            this.props[this.props.store].setActiveTab(2);
        } else {
            this.props[this.props.store].setActiveTab(3);
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.matchExact(nextProps.location.pathname, this.props.tab1.route)) {
            this.props[this.props.store].setActiveTab(1);
        }
    }

    matchExact(location, path) {
        return matchPath(location, {
            path: path,
            exact: true,
        });
    }

    setActiveTab(id) {
        this.props[this.props.store].setActiveTab(id);
    }
    
    onClick(clickedTab, route) {
        if(this.props.isSeedCheckout && clickedTab === 3) {
            this.props.appStore.goToCheckout(this.props.history)
        } else {
            this.setActiveTab(clickedTab);
            this.props.history.push(route)
        }
    }

    render() {
        const activeTab = this.props[this.props.store].activeTab;
        return (
            <div className="Seedpods-tabs-container">
                <div
                    className="Seedpods-tabs-background"
                    style={{width: activeTab === 1 ? "33.33%" : activeTab === 2 ? "66.66%" : "100%"}}/>
                <div className="Seedpods-navLinks-div">
                    <div
                        className="Seedpod-link"
                        style={{color: activeTab === 1 ? "white" : "rgba(58,95,11, 0.5)"}}>
                        <div className="Seedpod-tab" onClick={()=>this.onClick(1, this.props.tab1.route)}>
                            <p>{this.props.tab1.name}</p>
                        </div>
                    </div>
                    <div
                        className="Seedpod-link"
                        style={{color: activeTab === 3 ? "rgba(58,95,11, 0.5)" : activeTab === 2 ? "white" : "rgba(78, 84, 92, 0.3)"}}>
                        <div className="Seedpod-tab" onClick={()=>this.onClick(2, this.props.tab2.route)}>
                            <p>{this.props.tab2.name}</p>
                        </div>
                    </div>
                    <div
                        className="Seedpod-link"
                        style={{color: activeTab === 3 ? "white" : "rgba(78, 84, 92, 0.3)"}} >
                        <div className="Seedpod-tab" onClick={()=>this.onClick(3, this.props.tab3.route)}>
                            <p>{this.props.tab3.name}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NavTabs;
