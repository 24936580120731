import React, {Component} from 'react';
import {ButtonWhite} from "../../../../components/Buttons";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import EmptyMessage from "../../../../components/EmptyMessage";
import {formatDate} from "../../../../utils/UtilityFunctions";
import { PDFDownloadLink } from '@react-pdf/renderer'
import MyDocument from '../../../../components/pdfMaker'

@inject('orderStore')
@withRouter
@observer
class OrderDetails extends Component {

    componentWillMount() {
        this.props.orderStore.getOrder(this.props.location.state.id);
    }

    renderProducts() {
        return this.props.orderStore.order.products.map((product, index) => {
            return (
                <div key={index} className="row my-2">
                    <div className="col-4 Text-robo-sm">{product.name}</div>
                    <div className="col-4 Text-robo-sm text-right">{product.qty}</div>
                    <div className="col-4 Text-robo-sm text-right">{product.size}</div>
                    <div className="col-4 text-right">{product.price}</div>
                </div>
            )
        })
    }

    renderOrder() {
        if (this.props.orderStore.order) {
            const order = this.props.orderStore.order;
            return (
                <div>
                    <div className="Order-top-row pb-2">
                        <p className="Text-cat-title-2">{`Date: ${formatDate(order.orderdate)}` }</p>
                        <PDFDownloadLink document={<MyDocument order={order}/>} fileName="example.pdf">
                            {({ blob, url, loading, error }) => (
                                loading ? 'Loading document...' : <button className="Order-button-pdf Round-button-grey">
                                    <p className="Round-button-text-grey">PDF Invoice</p></button>
                            )}
                        </PDFDownloadLink>
                    </div>
                    <div className="py-3 border-bottom">
                        <p><b>Order Number: </b>{`${order.id}`}</p>
                        <p><b>Status: </b>{`${order.status}`} {`on ${formatDate(order.shipdate)}`}</p>
                        <p><b>Expected Delivery: </b>{`${formatDate(order.deliverdate)}`}</p>
                        <p><b>Tracking: </b>{`(${order.shippingprovider}) ${order.trackingnumber}`}</p>
                    </div>
                    <div className="py-4 border-bottom">
                        <div className="row mb-4">
                            <div className="col-4">Product</div>
                            <div className="col-4 text-right">Qty</div>
                            <div className="col-4 text-right">Size</div>
                            <div className="col-4 text-right">Price</div>
                        </div>
                        {this.renderProducts()}
                    </div>
                    <div className="py-4">
                        <div className="row my-2 justify-content-end">
                            <div className="col-4 text-right">Subtotal</div>
                            <div className="col-4 text-right">{order.subtotal.toFixed(2)}</div>
                        </div>
                        {order.discount !== 0 &&
                        <div className="row my-2 justify-content-end">
                            <div className="col-4 text-right">Discount</div>
                            <div className="col-4 text-right">- {order.discount.toFixed(2)}</div>
                        </div>
                        }
                        <div className="row my-2 justify-content-end">
                            <div className="col-4 text-right">Shipping</div>
                            <div className="col-4 text-right">+ {order.shipping.toFixed(2)}</div>
                        </div>
                        <div className="row my-2 justify-content-end">
                            <div className="col-4 Text-robo-sm text-right">Total</div>
                            <div className="col-4 Text-robo-sm text-right">{order.total.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <EmptyMessage message="You have no current subscription.">
                    <ButtonWhite
                        className="mx-auto mt-4"
                        label="Add Subscription"
                        onClick={() => this.props.history.push('/gropods')}
                    />
                </EmptyMessage>
            )
        }
    }

    render() {
        if (this.props.orderStore.isLoading) {
            return <div className="alert alert-light">Loading</div>
        }

        return (
            <div className="Subscription-contents">
                {this.renderOrder()}
            </div>
        );
    }
}

export default OrderDetails;
