import React, { Component } from 'react';
import AccountWidget from "../../components/forms/accountWidget/Index";
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class Support extends Component {
    render() {
        return (
            <div className="Content">
                <div className="Help-contents">
                    <p className="Text-robo-med Help-category-title mb-4">Contact Us</p>
                    <AccountWidget Contact noHeader Message buttonLabel="Send" onClick={(e)=>this.props.formStore.onContact(e)}/>
                </div>
            </div>
        );
    }
}

export default Support;