import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import logo from "../logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faShoppingBasket} from "@fortawesome/free-solid-svg-icons";
import {inject, observer} from "mobx-react";

@inject('cartStore', 'userStore', 'appStore')
@observer
class Navbar extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            showHamburger: false,
            activeTab:"home",
        };
    }
    
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    
    render() {
        return (
            <div>
                <div className = "Header">
                    <a className="Header-logo-div" href="https://gropod.io/">
                        <img src={ logo } className="Header-app-logo" alt="logo" onClick={()=>this.setState({isOpen: false})}  />
                    </a>
                    {this.props.appStore.width <= 800 &&
                    <div className="Header-hamburger-div">
                        <button onClick={this.toggle} className="Header-hamburger-button">
                            <FontAwesomeIcon icon={faBars} className="Header-hamburger-icon"  />
                        </button>
                    </div>
                    }
                    {this.props.appStore.width > 800 &&
                    <div className = "Nav-row">
                        <div className= "Header-tabs-container">
                            <NavLink exact={true} activeClassName="Header-active-tab" className="Header-link" to='/gropods'>
                                <div className="Header-tab">
                                    <p>gropod</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className= "Top-nav-container">
                            <NavLink exact={true} activeClassName="Top-nav-active" className="Top-nav-link" to='/myaccount'>
                                <p>My Account</p>
                            </NavLink>

                            <NavLink exact={true} activeClassName="Top-nav-active" className="Top-nav-link" to='/support'>
                                <p>Support</p>
                            </NavLink>
                            <NavLink exact={true} activeClassName="Top-nav-active" className="Top-nav-link" to='/shoppingcart'>
                                <p>Shopping Cart</p>
                                <div className="Shopping-icon-div">
                                    <div className="Shopping-number-circle">
                                        <span>{this.props.cartStore.numItemsInCart || 0}</span>
                                    </div>
                                    <div className="Shopping-icon-circle" >
                                        <FontAwesomeIcon icon={faShoppingBasket} className="Shopping-icon" />
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    }
                </div>
                <div className="Header-hamburger-contents text-center" style={{height:this.state.isOpen ? 180 : 0}}>
                    <NavLink className="Header-hamburger-link" activeClassName="Header-hamburger-link-active" exact={true} to='/gropods' onClick={this.toggle}>
                        <p>GROPOD</p>
                    </NavLink>
                    <NavLink className="Header-hamburger-link" activeClassName="Header-hamburger-link-active" exact={true} to='/support' onClick={this.toggle}>
                        <p>SUPPORT</p>
                    </NavLink>
                    {this.props.userStore.authUser &&  
                    <NavLink className="Header-hamburger-link" activeClassName="Header-hamburger-link-active" exact={true} to='/myaccount' onClick={this.toggle}>
                        <p>MY ACCOUNT</p>
                    </NavLink>
                    } 
                    <NavLink className="Header-hamburger-link" activeClassName="Header-hamburger-link-active" exact={true} to='/shoppingcart' onClick={this.toggle}>
                        <p>SHOPPING CART</p>
                    </NavLink>
                    <NavLink className="Header-hamburger-link" activeClassName="Header-hamburger-link-active" exact={true} to='/checkout' onClick={this.toggle}>
                        <p>CHECKOUT</p>
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default Navbar;
