import React, {Component} from 'react';
import HeaderRow, {Title} from "../../HeaderRow";
import TextInput from "../TextInput2";
import Dropdown from "../DropdownSelect";
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class Shipping extends Component {
    render() {
        const errors = this.props.formStore.errors;
        return (
            <div>
                <HeaderRow>
                    <Title text="Shipping Address"/>
                </HeaderRow>
                <div className="row">
                    <TextInput name="shippingAddress1" placeholder="Street Address 1" errors={errors["shipping.address1"]} />
                    <TextInput name="shippingAddress2" placeholder="Street Address 2" errors={errors["shipping.address2"]} />
                </div>
                <div className="row">
                    <TextInput name="shippingCity" placeholder="City" errors={errors["shipping.city"]} />
                    <Dropdown name="shippingState" placeholder="State" errors={errors["shipping.state"]} size="col-6 col-lg-3" className="size-lg"/>
                    <TextInput name="shippingZip" placeholder="Zip" errors={errors["shipping.zip"]} size="col-6 col-lg-3"/>
                </div>
            </div>
        );
    }
}

export default Shipping;
