import React, { Component } from "react";
import {inject, observer} from "mobx-react";
import InputMask from "react-input-mask";

@inject('formStore')
@observer
class TextInput extends Component {
    render() {
        const isInvalid = this.props.errors? "is-invalid": "";
        return (
            <div className={this.props.size || "col-12 col-lg-6"}>
                <div className="mb-3">
                    <InputMask
                        mask={this.props.mask}
                        type={this.props.type || "text"}
                        className={[ "form-control", isInvalid ].join(' ')}
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        value={this.props.value || this.props.formStore.form[this.props.name] || ""}
                        onChange={(input)=>this.props.formStore.onChangeInput(input)}
                        required={this.props.required}
                        pattern={this.props.pattern}
                    />
                    <div className="invalid-feedback">
                        {this.props.errors}
                    </div>
                </div>
            </div>
        );
    };
}

export default TextInput;
