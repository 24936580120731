import React, { Component } from "react";
import { Route } from "react-router-dom";
import {routes} from "./AccountRoutes";
import AccountMenu from "./AccountMenu";
import {inject, observer} from "mobx-react";

@inject('userStore', 'cartStore')
@observer
class MyAccount extends Component {
    componentWillMount() {
        this.props.userStore.getUser();
        this.props.cartStore.getCart();
    };

    renderPageTitle() {
        return routes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.title}
            />
        ))
    }

    renderPageContent() {
        return routes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.main}
            />
        ))
    }

    render() {
        document.title = 'GroPod Dashboard'
        return (
            <div className="Page-content">
                {this.renderPageTitle()}
                <div className="Account-double-row" >
                    <AccountMenu/>
                    <div className="Account-contents">
                        {this.renderPageContent()}
                    </div>
                </div>
            </div>
        );
    }
}

export default MyAccount;
