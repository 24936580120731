import React, {Component} from 'react';
import {ButtonGrey} from "../../../components/Buttons";
import {inject, observer} from "mobx-react";
import TextInput from "../../../components/forms/TextInput2";

@inject('cartStore', 'formStore')
@observer
class PromoCode extends Component {
    promoInput(size) {
        return (
            <TextInput
                name="promo"
                size={size}
                className="Account-standard-text-input Account-margin-right"
                placeholder="Promotion Code"
            />
        )
    }

    render() {
        if (this.props.width > 850) {
            return (
                <React.Fragment>
                    <p className="Cart-row-item-min"/>
                    <div className="Cart-row-item-2">
                        {this.promoInput("width-100")}
                    </div>
                    <div className="Cart-row-item">
                        <ButtonGrey
                            className="ml-auto"
                            label="Apply"
                            onClick={()=>this.props.formStore.applyPromo()}
                        />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <div className="Cart-small-container-row mt-0">
                    <div className="Cart-small-container-left"/>
                    <div className="Cart-small-container-right">
                        <div className="Cart-small-row border-bottom-0">
                            {this.promoInput("Cart-small-item")}
                            <div className="Cart-small-item text-right">
                                <ButtonGrey 
                                    className="ml-auto" 
                                    label="Apply"
                                    onClick={()=>this.props.formStore.applyPromo()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default PromoCode;
