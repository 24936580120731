import React, { Component } from "react";
import {inject, observer} from "mobx-react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import RelatedProducts from "./RelatedProducts";
import Cart from "./cartWidget/Cart";
import {ButtonGreen} from "../../components/Buttons";

@inject('cartStore', 'userStore', 'productStore')
@withRouter
@observer
class OrderSummary extends Component {
    
    componentWillMount() {
        this.props.userStore.getUser();
        this.props.productStore.getProducts();
    }

    render() {
        return (
            <div className="Content">
                <PageTitle
                    title= "Shopping Cart"
                    subtitle= ""
                />
                <Cart/>
                <ButtonGreen
                    className={"Cart-checkout-order-button ml-auto" + (this.props.cartStore.numItemsInCart === 0? " d-none": "")}
                    label="Checkout"
                    onClick={()=> this.props.history.push('/checkout')}
                />
                <RelatedProducts products = {this.props.productStore.products}/>
            </div>
        );
    }
}

export default OrderSummary;
