import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import DropdownSelect from "../../../../components/forms/DropdownSelect";
import QuantityFilled from "../../QuantityFilled";
import SubscriptionPackage from "../../SubscriptionPackage";
import PageTitle from "../../../../components/PageTitle";
import Search from "../../../../components/forms/Search";
import SortBy from "../../../../components/forms/SortBy";
import {CSSTransition} from "react-transition-group";

@inject('subscriptionStore', 'seedStore')
@observer
class Customize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    async componentWillMount() {
        await this.props.seedStore.getSeeds();
    }
    
    renderSeedPods = () => {
        if (this.props.seedStore.isLoading) {
            return false
        }

        return this.props.seedStore.seeds.map((seed, i) => {
            const visible = this.props.seedStore.searchResults.length === 0 || this.props.seedStore.searchResults.includes(seed.id);
            return (
                <div key={i}>
                    <CSSTransition
                        in={visible}
                        timeout={500}
                        classNames="seed"
                        unmountOnExit
                    >
                        <div className="Customize-seed-row mb-4">
                            <div className="Customize-img-div">
                                <img src={seed.img} alt="Seedpod" className="Customize-seed-img"/>
                            </div>
                            <div className="flex-wrap-row">
                                <div className="Customize-seed-description">
                                    <p className="Text-small-title-black">{`${seed.name}`}</p>
                                    <p className="mb-2">{`${seed.description}`} </p>
                                </div>
                                <div className="Customize-seed-dropdown">
                                    <DropdownSelect
                                        size="Customize-qty-dropdown"
                                        name={seed.name}
                                        placeholder="Qty"
                                        optionsKey="seedpodOptions"
                                        value={this.props.seedStore.myCustomSeeds[seed.name]}
                                        onChange={(name, input) => this.props.seedStore.addSeeds(name, input)}
                                    />
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            )
        })
    };

    render() {
        document.title = 'Customize Your Subscription - GroPod'
        return (
            <div>
                <PageTitle
                    title="Customize Gropod Subscriptions"
                />
                <div className="Customize-row">
                    <div className="Customize-left mb-5">
                        <div className="Customize-top-row">
                            <div className="Customize-top-column">
                                <Search/>
                            </div>
                            <div className="Customize-top-column d-none">
                                <SortBy/>
                            </div>
                        </div>
                        <div className="row justify-content-between mt-3 mx-0 border-bottom">
                            <p className="Text-small-title">Choose Gropod</p>
                            <QuantityFilled/>
                        </div>
                        {this.renderSeedPods()}
                    </div>
                    <div className="PreSelect-right">
                        <SubscriptionPackage/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Customize;
