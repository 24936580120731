import React, {Component} from 'react';
import HeaderRow, {Title} from "../../HeaderRow";
import {inject, observer} from "mobx-react";
import StripeForm from "./StripeForm";

@inject('formStore')
@observer
class CreditCard extends Component {
    render() {
        return (
            <div>
                <HeaderRow>
                    <Title text="Card Information"/>
                </HeaderRow>
                <StripeForm updatePayment={this.props.updatePayment}/>
            </div>
        );
    }
}

export default CreditCard;
