import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class CartItemsRemoveModal extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}></ModalHeader>
                    <ModalBody className="px-5 py-4">
                        <div className="Text-robo-med mb-4">Are you sure?</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.props.toggle}>No</Button>
                        <Button color="primary" onClick={this.props.removeAllItems}>Yes</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default CartItemsRemoveModal;
