import React, {Component} from 'react';
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class Checkbox extends Component {
    onChange(input) {
        if(this.props.onChange) {
            this.props.onChange(input);
        } else {
            this.props.formStore.onChangeInput(input);
        }
    }
    
    render() {
        return (
            <input
                name={this.props.name}
                type="checkbox"
                defaultChecked={this.props.checked || this.props.formStore[this.props.name]}
                onChange={(input) => this.onChange(input)}
                id="defaultCheck1"
            />
        );
    }
}

export default Checkbox;
