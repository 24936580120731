import React, {Component} from 'react';
import {inject, observer} from "mobx-react";

@inject('seedStore')
@observer
class QuantityFilled extends Component {
    render() {
        return (
            <p className="Text-contentGrey-robo-reg-18 mb-2">
                {this.props.seedStore.filledSeeds}/
                {this.props.seedStore.mySize.qty} filled
            </p>
        );
    }
}

export default QuantityFilled;
