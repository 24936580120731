import {observable, action} from 'mobx';
import appFetch from '../utils/AppFetch'
import routes from '../utils/ApiRoutes'
import transform from '../transformers/UserTransformer'
import formStore from './FormStore'

class UserStore {
    @observable isLoading = false;
    @observable showMenu = false;
    @observable authUser = {};
    @observable gropod = {};

    @action async getUser() {
        this.isLoading = true;
        const api = routes.user.getUser();
        const returnData = await appFetch(api.url);
        if (returnData.httpStatus === 200) {
            localStorage.setItem('isLoggedIn', true);
            this.authUser = transform.toForm(returnData);
            formStore.form = this.authUser;
            this.authUser.gropod = returnData.gropods[0];
            const gropodApi = routes.user.getGropod(this.authUser.gropod);
            const gropodReturnData = await appFetch(gropodApi.url);
            if(gropodReturnData.httpStatus === 200) {
                this.gropod = gropodReturnData;
            } else {
                this.gropod = {};
            }
            this.authUser.subscription = returnData.subscription;
            this.authUser.savedPayment = returnData.savedpayment;
            this.isLoading = false;
        } else if (returnData.httpStatus === 401) {
            localStorage.setItem('isLoggedIn', false);
            this.authUser = {};
            this.isLoading = false;
        } else {
            this.authUser = {};
            this.isLoading = false;
        }
    }

    @action async getGropod() {
        this.isLoading = true;
        const api = routes.user.getGropod(this.authUser.gropod);
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.gropod = returnData;
            this.isLoading = false;

        } else {
            this.gropod = {};
            this.isLoading = false;
        }
    }

    @action toggleMenu() {
        this.showMenu = !this.showMenu;
    }
}

export default new UserStore();
