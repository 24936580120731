import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Route } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { ButtonGreen } from "../../../../components/Buttons";
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

@inject('userStore')
@observer
class LiveView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
        };
    }

    Button = () => (
        <Route render={({ history }) => (
            <ButtonGreen className="Liveview-dashboard-btn" label="Dashboad Summary" onClick={() => { history.push('../myaccount/') }} />
        )} />
    );

    render() {

        return (
            <div className="Help-contents">
                {
                    (this.props.userStore.gropod && this.props.userStore.gropod.temperature) ?

                        (this.state.activeTab === 1) ?
                            <div className="Help-video-div" style={{width: "100%", height: "auto"}}>
                                <img src={`https://s3.amazonaws.com/gropodimages.${process.env.NODE_ENV}.gropod.io/${this.props.userStore.gropod.serial}/latest.jpg`} alt="Plants" style={{ "width": "100%", "height": "auto", align: "center" }} />
                            </div> :
                            <div>
                                <p className="Liveview-timelapse-content">Timelapse feature coming soon!</p>
                            </div>
                        :
                        <div>
                            <p className="Liveview-timelapse-content">No GroPod device synced to your account. Please contact support@gropod.io</p>
                        </div>
                }

                <div className="Liveview-button-row">
                    <div className="Liveview-links-div">
                        <div className={this.state.activeTab === 1 ? "Liveview-link-div-active Liveview-link-left" : "Liveview-link-div Liveview-link-left"}
                            onClick={() => { this.setState({ activeTab: 1 }) }}>
                            <div><p>Plant View</p></div>
                        </div>
                        <div className={this.state.activeTab === 2 ? "Liveview-link-div-active Liveview-link-right" : "Liveview-link-div Liveview-link-right"}
                            onClick={() => { this.setState({ activeTab: 2 }) }}>
                            <div className="" onClick={() => { this.setState({ activeTab: 2 }) }}>
                                <p>Time Lapse</p>
                            </div>
                        </div>
                    </div>
                    {
                        <div>

                            <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.gropod.io?imageUrl=http://images.gropod.io/new-plants.jpg">
                                <FontAwesomeIcon icon={faFacebookSquare} className="Footer-social-icon" /></a>

                            {/* <a href="https://www.instagram.com/gropodofficial/">
                                <FontAwesomeIcon icon={faInstagram} className="Footer-social-icon" /></a> */}

                            <a href="http://images.gropod.io/new-plants.jpg" download>
                                <FontAwesomeIcon icon={faFileDownload} className="Footer-social-icon" />
                            </a>
                        </div>
                    }
                </div>
                {this.Button()}
            </div>
        );
    }
}

export default LiveView;