import React, { Component } from 'react';
import TextInput from "../TextInput2";
import { inject, observer } from "mobx-react";
import { Title } from "../../HeaderRow";
import HeaderRow from "../../HeaderRow";

@inject('formStore')
@observer
class NewPassword extends Component {
    render() {
        const errors = this.props.formStore.errors;
        return (
            <div>
                <HeaderRow className={"align-items-end pb-1"}>
                    <Title text="Password" />
                </HeaderRow>
                <div className="row">
                    <TextInput name="oldPassword" placeholder="Old Password" type="password" errors={errors.oldpassword} />
                    <TextInput name="newPassword" placeholder="New Password" type="password" errors={errors.password1 || errors.newpassword} />
                    <TextInput name="confirmPassword" placeholder="Confirm Password" type="password" errors={errors.password2 || errors.newpassword} />
                </div>
            </div>
        );
    }
}

export default NewPassword;
