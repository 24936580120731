import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import CartItems from "./CartItems";
import PromoCode from "./PromoCode";
import EmptyMessage from '../../../components/EmptyMessage'
import {ButtonWhite} from "../../../components/Buttons";

@inject('cartStore', 'appStore')
@withRouter
@observer
class Cart extends Component {
    render() {
        if(this.props.cartStore.isLoading) {
            return <div className="alert alert-light">Loading...</div>
        }
        
        if(this.props.cartStore.numItemsInCart !== 0) {
            return (
                <div className="w-100">
                    <div className="Cart-chart mt-5" style={{marginBottom: 32}}>
                        {this.props.appStore.width > 850 && <div className="Cart-title-row" style={{marginTop: 0}}>
                            <div className="Cart-row-item-min">
                                <div className="Cart-row-item-min-div"/>
                            </div>
                            <p className="Cart-row-item-2">Product Name</p>
                            <p className="Cart-row-item">Quantity</p>
                            <p className="Cart-row-item text-right">Price</p>
                            <p className="Cart-row-item text-right">Subtotal</p>
                        </div>}
                        <CartItems width={this.props.appStore.width}/>
                        <div className="Cart-final-row Cart-final-row-shopping">
                            <PromoCode width={this.props.appStore.width}/>
                        </div>
                        <div className="Cart-final-row Cart-final-row-shopping mt-3">
                            <p className="Cart-row-item-3 text-right">Subtotal</p>
                            <p className="Cart-row-item Text-cat-title-2 text-right">{`$${this.props.cartStore.cart.presaletotal}`}</p>
                        </div>
                        {this.props.cartStore.cart.promodiscount !== 0 &&
                        <div className="Cart-final-row Cart-final-row-shopping mt-3">
                            <p className="Cart-row-item-3 text-right">Discount</p>
                            <p className="Cart-row-item Text-cat-title-2 text-right text-success">{`- $${this.props.cartStore.cart.promodiscount}`}</p>
                        </div>
                        }
                        {this.props.cartStore.cart.salediscount !== 0 &&
                        <div className="Cart-final-row Cart-final-row-shopping mt-3">
                            <p className="Cart-row-item-3 text-right">Sale</p>
                            <p className="Cart-row-item Text-cat-title-2 text-right text-success">{`- $${this.props.cartStore.cart.salediscount}`}</p>
                        </div>
                        }
                        <div className="Cart-final-row Cart-final-row-shopping mt-3">
                            <p className="Cart-row-item-3 text-right">Shipping</p>
                            <p className="Cart-row-item Text-cat-title-2 text-right">{`$${this.props.cartStore.cart.shippingprice}`}</p>
                        </div>
                        <div className="Cart-final-row Cart-final-row-shopping mt-3">
                            <p className="Cart-row-item-3 text-right">Total</p>
                            <p className="Cart-row-item Text-cat-title-2 text-right">{`$${this.props.cartStore.cart.total.toFixed(2)}`}</p>
                        </div>
                    </div>
                </div>
            ); 
        } else {
            return (
                <div className="mb-5 w-100">
                    <EmptyMessage message="Your cart is empty.">
                        <ButtonWhite
                            className="mx-auto mt-4"
                            label="Shop Products"
                            onClick={() => this.props.history.push('/gropods')}
                        />
                    </EmptyMessage>
                </div>
            )
            
        }
    }
}

export default Cart;
