import React, {Component} from 'react';

export class ButtonGrey extends Component {
    render() {
        return (
            <Button
                buttonClass={this.props.className + " Round-button-grey"}
                contentClass="Round-button-content-div"
                textClass="Round-button-text-grey"
                label={this.props.label}
                isDisabled={this.props.isDisabled}
                onClick={this.props.onClick}
            >
            </Button>
        );
    }
}

export class ButtonGreen extends Component {
    render() {
        return (
            <Button
                buttonClass={"Round-button " + this.props.className }
                contentClass="Round-button-content-div"
                textClass="Round-button-text"
                label={this.props.label}
                isDisabled={this.props.isDisabled}
                onClick={this.props.onClick}
            >
            </Button>
        );
    }
}

export class ButtonWhite extends Component {
    render() {
        return (
            <Button
                buttonClass={"Round-button-white " + this.props.className }
                contentClass="Round-button-content-div"
                textClass="Round-button-text"
                label={this.props.label}
                onClick={this.props.onClick}
            >
            </Button>
        );
    }
}

export class ButtonSquare extends Component {
    render() {
        return (
            <Button
                buttonClass={"Button-squared " + this.props.className }
                contentClass="Button-content-div-square"
                textClass="Button-text-square"
                height={this.props.height}
                label={this.props.label}
                onClick={this.props.onClick}
            >
            </Button>
        );
    }
}

export class ButtonSquareWhite extends Component {
    render() {
        return (
            <Button
                buttonClass={"Button-squared-white " + this.props.className }
                contentClass="Button-content-div-square"
                textClass="Button-text-square"
                height={this.props.height}
                label={this.props.label}
                onClick={this.props.onClick}
            >
            </Button>
        );
    }
}

class Button extends Component {
    render() {
        return (
            <button 
                className={this.props.buttonClass} type={this.props.type} 
                onClick={(e, props)=>this.props.onClick(e, props)}
                style={{height: this.props.height, width: this.props.width}}
                disabled={this.props.isDisabled}
            >
                <div className={this.props.contentClass}>
                    <p className={this.props.textClass}>{this.props.label}</p>
                </div>
            </button>
        );
    };
}
