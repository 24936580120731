const user = {
    login: () => (
        { url: `auth`, method: 'POST' }
    ),
    getUser: () => (
        { url: `user`, method: 'GET' }
    ),
    newUser: () => (
        { url: `user`, method: 'PUT', }
    ),
    editUser: () => (
        { url: `user`, method: 'POST' }
    ),
    resetPassword: () => (
        { url: `reset-password`, method: 'POST' }
    ),
    getGropod: (id) => (
        { url: `gropod/${id}`, method: 'GET' }
    ),
    forgotPasswordRequest: () => (
        { url: `forgot-password`, method: 'POST' }
    )
};

const cart = {
    getCart: () => (
        { url: `cart`, method: 'GET' }
    ),
    addItem: () => (
        { url: `cart`, method: 'PUT', }
    ),
    updateQty: () => (
        { url: `cart`, method: 'POST' }
    ),
    checkout: () => (
        { url: `checkout`, method: 'POST' }
    ),
};

const products = {
    getProducts: () => (
        { url: `products`, method: 'GET' }
    ),
    getProduct: (id) => (
        { url: `products/${id}`, method: 'GET', id: id }
    ),
    reviewProduct: (id) => (
        { url: `products/${id}/review`, method: 'PUT', id: id }
    ),
};

const subscription = {
    getSeadpod: () => (
        { url: `seedpod`, method: 'GET' }
    ),
    getPackages: () => (
        { url: `seedpod-package`, method: 'GET' }
    ),
    getShipments: (id) => (
        { url: `subscription-shipments`, method: 'GET', id: id }
    ),
    getSizes: () => (
        { url: `subscription-size`, method: 'GET' }
    ),
    getMySubscription: () => (
        { url: `subscription`, method: 'GET' }
    ),
    checkout: () => (
        { url: `checkout`, method: 'POST' }
    ),
    newSubscription: () => (
        { url: `subscription`, method: 'POST' }
    ),
    updateSubscription: () => (
        { url: `subscription`, method: 'PUT' }
    ),
    skipSubscription: () => (
        { url: `subscription/skip`, method: 'POST' }
    ),
};

const orders = {
    getOrders: () => (
        { url: `orders`, method: 'GET' }
    ),
    getOrder: (id) => (
        { url: `orders/${id}`, method: 'GET', id: id }
    ),
};

const contact = {
    contactUs: () => (
        { url: `contact`, method: 'PUT' }
    ),
};

export default {
    user,
    cart,
    products,
    subscription,
    orders,
    contact
};
