import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";

@inject('seedStore')
@observer
class SortBy extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            sortItem: "Sort by",
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    
    onSort(value) {
        this.setState({sortItem: value})
    }
    
    render() {
        return (
            <div className="Customize-sort-row">
                <p className="Text-standard-font-sm">Sort by:&nbsp;&nbsp;</p>
                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle caret color="primary" className="text-white">
                        {this.state.sortItem} &nbsp;
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>PRICE</DropdownItem>
                        <DropdownItem onClick={this.onSort.bind(this, "Price: Low-High")}>Low-High</DropdownItem>
                        <DropdownItem onClick={this.onSort.bind(this, "Price: High-Low")}>High-Low</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
                {/*<DropdownSelect*/}
                {/*size="Customize-qty-dropdown"*/}
                {/*placeholder="PRICE: LOW-HIGH"*/}
                {/*optionsKey="seedpodOptions"*/}
                {/*onChange={(name, input) => this.props.seedStore.addSeeds(name, input)}*/}
                {/*/>*/}
                {/*<Dropdown*/}
                {/*width={160}*/}
                {/*iconStyle={{color: "white", fontSize: 12}}*/}
                {/*height={26}*/}
                {/*textStyle="Text-robo-bold-11-white"*/}
                {/*optionArray={[10, 20, 30, 40, 50, 60]}*/}
                {/*placeHolder="PRICE: LOW-HIGH"*/}
                {/*className="Customize-sort-dropdown"*/}
                {/*selectedItem={this.state.selectedItem}*/}
                {/*selectItem={((item) => this.setState({selectedState: item}))}*/}
                {/*/>*/}
            </div>
        );
    }
}

export default SortBy;
