import React, {Component} from 'react';

export default class Tutorials extends Component {
    category1(tutorials) {
        return tutorials.map((tutorial, index) => {
            return tutorial.category === 1 ? this.renderTutorial(tutorial, index) : null
        })
    }

    category2(tutorials) {
        return tutorials.map((tutorial, index) => {
            return tutorial.category === 2 ? this.renderTutorial(tutorial, index) : null
        })
    }

    renderTutorial(tutorial, index) {
        return (
            <div key={index} className="my-3">
                <p className="Text-cat-title-2-green Help-tutorial-link"
                    onClick={()=>this.props.onClick(tutorial)}>Tutorial link</p>
                <p className="Text-robo-reg-small">{tutorial.description}</p>
            </div>
        )
    }
    
    render() {
        return (
            <div>
                <p className="Text-cat-title-2">{this.props.activeTutorial.title}</p>
                <p className="Text-robo-reg-small">{this.props.activeTutorial.description}</p>
                <p className="Text-robo-med Help-category-title">Tutorial Category 1</p>
                {this.category1(this.props.tutorials)}
                <p className="Text-robo-med Help-category-title">Tutorial Category 2</p>
                {this.category2(this.props.tutorials)}
            </div>
        );
    }
}
