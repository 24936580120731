import React, {Component} from 'react';
import {inject, observer} from "mobx-react";

@inject('cartStore')
@observer
class CartItems extends Component {
    updateCartQuantity(id, qty) {
        this.props.cartStore.updateQty(id, qty);
    }

    quantityRow(product) {
        return (
            <React.Fragment>
                <div
                    className="Cart-qty-plus-div"
                    onClick={() => {
                        this.updateCartQuantity(product.id, product.qty - 1)
                    }}>
                    <p className="Cart-qty-plus">–</p>
                </div>
                <div className="Cart-qty-div"><p>{product.qty}</p></div>
                <div
                    className="Cart-qty-plus-div"
                    onClick={() => {
                        this.updateCartQuantity(product.id, product.qty + 1)
                    }}>
                    <p className="Cart-qty-plus">+</p>
                </div>
            </React.Fragment>
        )
    }

    render() {
        return this.props.cartStore.cart.products.map((product, i) => {
            return (
                <div key={i}>
                    {this.props.width > 850 ?
                        <div className="Cart-item-row">
                            <div className="Cart-row-item">
                                <img alt="Product" src={product.thumbnail} className="Cart-item-img"/>
                            </div>
                            <div className="Cart-row-item-2"><p className="Text-cat-title-2">{`${product.name}`}</p>
                            </div>
                            <div className="Cart-row-item">
                                <div className="Cart-quantity-row">
                                    {this.quantityRow(product)}
                                </div>
                            </div>
                            <div className="Cart-row-item text-right"><p>{`$${product.price}`}</p></div>
                            <div className="Cart-row-item text-right"><p>{`$${product.price * product.qty}`}</p>
                            </div>
                        </div>
                        :
                        <div className="Cart-small-container-row">
                            <div className="Cart-small-container-left">
                                <img src={product.thumbnail} alt="Product" className="Cart-item-img"/>
                            </div>
                            <div className="Cart-small-container-right">
                                <p className="Text-cat-title-2">{`${product.name}`}</p>
                                <div className="Cart-small-row">
                                    <p className="Cart-small-item">Quantity</p>
                                    <div className="Cart-quantity-row Cart-small-item">
                                        {this.quantityRow(product)}
                                    </div>
                                </div>
                                <div className="Cart-small-row">
                                    <p className="Cart-small-item">Price</p>
                                    <div className="Cart-small-item"><p>{`$${product.price}`}</p></div>
                                </div>
                                <div className="Cart-small-row">
                                    <p className="Cart-small-item">Subtotal</p>
                                    <div className="Cart-small-item"><p>{`$${product.price * product.qty}`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )
        })
    };
}

export default CartItems;
