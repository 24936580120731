import React, { Component } from 'react';
import HeaderRow, { Title, Checkbox } from "../../HeaderRow";
import Billing from "./Billing";
import Contact from "./Contact";
import Message from "./Message";
import Card from "./CreditCard";
import NewPassword from "./NewPassword";
import Shipping from "./Shipping";
import { ButtonGreen } from "../../Buttons";
import { inject, observer } from "mobx-react";
import RegisterPassword from './RegisterPassword';

@inject('formStore')
@observer
class AccountWidget extends Component {

    render() {
        const submitButton = <ButtonGreen label={this.props.buttonLabel || "Submit"} className={this.props.buttonClass} onClick={this.props.onClick} />;

        return (
            <form onSubmit={this.props.onSubmit}>
                { /* Contact information when contacting support at /support */ }
                {this.props.Contact &&
                    <Contact
                        smallForm={this.props.smallForm}
                        AccountPage={this.props.AccountPage}
                        noHeader={this.props.noHeader}
                    />}
                { /* Textarea when contacting support at /support */ }
                {this.props.Message && <Message />}
                { /* Shipping address section when editing account in /myaccount/account */ }
                {this.props.Shipping && <Shipping smallForm={this.props.smallForm} />}
                { /* Card information section when editing account in /myaccount/account */ }
                {this.props.Card &&
                    <Card
                        smallForm={this.props.smallForm}
                        checkout={this.props.SubscriptionCheckout}
                        updatePayment={this.props.updatePayment}
                    />
                }
                {
                    /* Password form when creating a new account at /register */
                    this.props.RegisterPassword && <RegisterPassword />
                }
                { /* Billing address section when editing account in /myaccount/account */ }
                {this.props.Billing && <Billing smallForm={this.props.smallForm} />}
                { /* New password section when editing account in /myaccount/account
                    This is only the checkbox next to Change Password, input is below */ }
                {
                    this.props.newPassword &&
                    <div>
                        <HeaderRow>
                            <Title text="Change Password" />
                            <Checkbox
                                name="changePassword"
                                text="Change Password"
                                checked={this.props.formStore.form["changePassword"]}
                                onChange={(input) => this.props.formStore.onChangePassword(input)}
                            />
                        </HeaderRow>
                    </div>
                }
                { /* Input for new password when editing account in /myaccount/account */ }
                {this.props.formStore.form["changePassword"] && <NewPassword />
                }

                {!this.props.buttonTop && submitButton}
            </form>
        );
    }
}

export default AccountWidget;
