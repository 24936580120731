import {observable, action} from 'mobx';
import appFetch from '../utils/AppFetch'
import routes from '../utils/ApiRoutes'

class OrderStore {
    @observable isLoading = false;
    @observable myOrders = {};
    @observable orders = {};
    @observable order = {};

    @action
    async getOrders() {
        this.isLoading = true;
        const api = routes.orders.getOrders();
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.myOrders = returnData;
            this.isLoading = false;
        }
    }

    @action
    async getOrder(id) {
        this.isLoading = true;
        const api = routes.orders.getOrder(id);
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.order = returnData;
            this.isLoading = false;
        }
    }
}

export default new OrderStore();
