import React, { Component } from 'react'
import HeaderRow, { Title } from '../../HeaderRow'
import TextInput from '../TextInput2'
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class RegisterPassword extends Component {
    render() {
        const errors = this.props.formStore.errors

        return (
            <div>
                <HeaderRow>
                    <Title text="Password" />
                </HeaderRow>
                <div className="row">
                    <TextInput name="newPassword" type="password" placeholder="Password" errors={errors["password"]} />
                    <TextInput name="confirmPassword" type="password" placeholder="Confirm Password" errors={errors["password2"]} />
                </div>
            </div>
        )
    }
}

export default RegisterPassword