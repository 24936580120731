import React, { Component } from 'react';
import {ButtonGrey} from "../components/Buttons";

import {inject, observer} from "mobx-react";

const visaImg = require("../assets/visa.png");

@inject('userStore')
@observer
class ViewDetailsWidget extends Component {

    onSubmit = e => {
        e.preventDefault();
    };

    render() {
        const user = this.props.userStore.authUser;
        return (
            <div className="Account-form-div">
                {this.props.Contact &&
                <div>
                    {this.props.AccountPage ?
                        <div className="Account-view-top-div">
                            <p className="Text-cat-title-2 ">Contact Information</p>
                            <div>
                                <ButtonGrey className="Account-save-button" label="Edit" onClick={this.props.onEditClick}/>
                            </div>
                        </div> :
                        <p className="Text-cat-title-2 Account-view-section-title">Contact Information</p>
                    }
                    <div className="Account-view-row">
                        <p className="Account-view-left-item">Name</p>
                        <p className="Account-view-right-item text-capitalize">{`${user.firstName} ${user.lastName}`}</p>
                    </div>
                    <div className="Account-view-row">
                        <p className="Account-view-left-item">Email</p>
                        <p className="Account-view-right-item">{`${user.email}`}</p>
                    </div>
                    <div className="Account-view-row">
                        <p className="Account-view-left-item">Phone</p>
                        <p className="Account-view-right-item">{`${user.phone}`}</p>
                    </div>
                </div>
                }
                {this.props.Shipping &&
                <div>
                    <p className="Text-cat-title-2 Account-view-section-title">Shipping Address</p>
                    <div className="Account-view-row">
                        <p className="Account-view-left-item">Shipping Address</p>
                        <div className="Account-view-right-item">
                            <p>{`${user.shippingAddress1}`}</p>
                            <p>{`${user.shippingAddress2}`}</p>
                            <p>{`${user.shippingCity}, ${user.shippingState? user.shippingState.value: ""} ${user.shippingZip}`}</p>
                        </div>
                    </div>
                </div>
                }
                {this.props.Card &&
                <div>
                    <p className="Text-cat-title-2 Account-view-section-title">Card Information</p>
                    <div className="Account-view-row">
                        <p className="Account-view-left-item">Card Number</p>
                        <div className="Account-card-row Account-view-right-item">
                            <p>{`**** **** **** ${user.cardNumber}`}</p>
                            <img src={visaImg} alt="Visa Card" className="Account-view-ccImage" />
                        </div>
                    </div>
                </div>
                }
                {this.props.Billing &&
                <div>
                    <p className="Text-cat-title-2 Account-view-section-title">Billing Address</p>
                    <div className="Account-view-row">
                        <p className="Account-view-left-item">Billing Address</p>
                        <div className="Account-view-right-item">
                            {user.billingAddress1 ?
                                <div>
                                    <p>{`${user.billingAddress1}`}</p>
                                    <p>{`${user.billingAddress2}`}</p>
                                    <p>{`${user.billingCity}, ${user.billingState? user.billingState.value: ""} ${user.billingZip}`}</p>
                                </div> : 
                                <div>None</div>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default ViewDetailsWidget;
