import React, { Component } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selectedItem: null,
        };
    }

    toggle = (e) => {
        e.preventDefault();
        this.setState({isOpen: !this.state.isOpen});
    };

    pressOption = (option, e) => {
        this.props.selectItem(option);
        this.toggle(e);
    };

    render() {
        return (
            <div className="Dropdown-div" style={{width: this.props.width, height: this.props.height ? this.props.height : 40, flex: this.props.flex ? this.props.flex : null}}>
                <button
                    className={ [ "Dropdown-button", this.props.className ].join(' ')}
                    onClick={(e) => this.toggle(e)}
                    style={{width: this.props.width}}
                >
                    <div className="Dropdown-button-div" >
                        {(this.props.placeHolder && !this.props.selectedItem) &&
                        <p className={this.props.textStyle ? this.props.textStyle : "Text-standard-font-footer"}>{this.props.placeHolder}</p>}
                        <p className={this.props.textStyle ? this.props.textStyle : "Text-standard-font-footer"}>{this.props.selectedItem}</p>
                        <FontAwesomeIcon icon={faCaretDown} className={this.state.isOpen ? "Dropdown-caret-up" : "Dropdown-caret-down"} style={this.props.iconStyle}/>
                    </div>
                </button>
                {this.state.isOpen &&

                    <div
                        className="Dropdown-contents"
                        style={{width: this.props.width, top: this.props.top}}
                    >
                        <div>
                            {this.props.optionArray.map((option, i) => {
                                return(<p key={i} className="Dropdown-item" onClick={(e)=>this.pressOption(option, e)}>{option}</p>)
                            })}
                        </div>
                    </div>
                }
            </div>
        );

    };
}

// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
//
// /**
//  * Component that alerts if you click outside of it
//  */
// export default class OutsideAlerter extends Component {
//   constructor(props) {
//     super(props);
//
//     this.setWrapperRef = this.setWrapperRef.bind(this);
//     this.handleClickOutside = this.handleClickOutside.bind(this);
//   }
//
//   componentDidMount() {
//     document.addEventListener('mousedown', this.handleClickOutside);
//   }
//
//   componentWillUnmount() {
//     document.removeEventListener('mousedown', this.handleClickOutside);
//   }
//
//   /**
//    * Set the wrapper ref
//    */
//   setWrapperRef(node) {
//     this.wrapperRef = node;
//   }
//
//   /**
//    * Alert if clicked on outside of element
//    */
//   handleClickOutside(event) {
//     if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
//       alert('You clicked outside of me!');
//     }
//   }
//
//   render() {
//     return <div ref={this.setWrapperRef}>{this.props.children}</div>;
//   }
// }
//
// OutsideAlerter.propTypes = {
//   children: PropTypes.element.isRequired,
// };
