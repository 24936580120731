import React, { Component } from 'react';
import {NavLink, withRouter} from "react-router-dom";
import { inject, observer } from "mobx-react";
import TextInput from "../../components/forms/TextInput2";
import RoundButton from "../../components/RoundButton";

@inject('formStore')
@withRouter
@observer
class Login extends Component {
    render() {
        return (
            <div className="Page-content">
                <div className="Account-title-div" style={{ marginBottom: 43 }}>
                    <h2 className="Text-title-large">Login</h2>
                </div>
                <form className="Login-form-div" onSubmit={(e) => this.props.formStore.onLogin(e, this.props.history)}>
                    <TextInput name="email" placeholder="Email" type="email" errors={this.props.formStore.email} />
                    <TextInput name="password" placeholder="Password" type="password" errors={this.props.formStore.password} />
                    <RoundButton type='submit' className="Seed-checkout-order-button" label={`Login`} /> <br />
                    <NavLink exact={true} to='/forgot-password' activeClassName="Footer-nav-active" className="Footer-nav-link">
                        <p className="Text-standard-font-footer" >Forgot Password</p>
                    </NavLink>
                </form>
            </div>
        );
    }
}

export default Login;
