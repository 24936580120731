import DropdownStore from "../stores/DropdownStore";

const contact = {
    toForm: (user) => (
        {
            firstName: user.first || "",
            lastName: user.last || "",
            email: user.email || "",
            phone: user.phone || "",
        }
    ),
    fromForm: (user) => (
        {
            first: user.firstName,
            last: user.lastName,
            email: user.email,
            phone: user.phone,
        }
    ),
};

const card = {
    toForm: (user) => (
        {
            cardNumber: (user.savedpayment && user.savedpayment.last4) || "",
            password2: user.confirmPassword,
        }
    ),
    fromForm: (user) => (
        {
            password: user.newPassword,
            password2: user.confirmPassword,
        }
    ),
};

const shipping = {
    toForm: (user) => (
        {
            shippingAddress1: (user.shipping && user.shipping.address1) || "",
            shippingAddress2: (user.shipping && user.shipping.address2) || "",
            shippingCity: (user.shipping && user.shipping.city) || "",
            shippingState: (user.shipping && getRegion(user.shipping.state)) || "",
            shippingZip: (user.shipping && user.shipping.zip) || "",
        }
    ),
    fromForm: (user) => (
        {
            shipping: {
                address1: user.shippingAddress1,
                address2: user.shippingAddress2,
                city: user.shippingCity,
                state: user.shippingState ? user.shippingState.value : null,
                zip: user.shippingZip,
            },
        }
    ),
};

const billing = {
    toForm: (user) => (
        {
            billingAddress1: (user.billing && user.billing.address1) || "",
            billingAddress2: (user.billing && user.billing.address2) || "",
            billingCity: (user.billing && user.billing.city) || "",
            billingState: (user.billing && getRegion(user.billing.state)) || "",
            billingZip: (user.billing && user.billing.zip) || "",
        }
    ),
    fromForm: (user) => (
        {
            billing: {
                address1: user.billingAddress1,
                address2: user.billingAddress2,
                city: user.billingCity,
                state: user.billingState ? user.billingState.value : null,
                zip: user.billingZip,
            },
        }
    ),
};

const password = {
    toForm: (user) => (
        {
            password: user.newPassword,
            password2: user.confirmPassword,
        }
    ),
    fromForm: (user) => (
        {
            password: user.newPassword,
            password2: user.confirmPassword,
        }
    ),
};

const changePassword = {
    toForm: (user) => (
        {
            oldpassword: user.oldPassword,
            newpassword: user.newPassword,
            password2: user.confirmPassword
        }
    ),
    fromForm: (user) => (
        {
            oldpassword: user.oldPassword,
            newpassword: user.newPassword,
            password2: user.confirmPassword
        }
    ),
}

const message = {
    fromForm: (user) => (
        {
            message: user.message,
        }
    ),
};

function getRegion(abbr) {
    let selectedRegion = {};
    for (let region of DropdownStore.regionOptions) {
        if (region.value === abbr)
            selectedRegion = region
    }
    return selectedRegion;
}

export function fromForm(data, components) {
    const contact2 = components.contact ? contact.fromForm(data) : {};
    const shipping2 = components.shipping ? shipping.fromForm(data) : {};
    const billing2 = components.billing ? billing.fromForm(data) : {};
    const password2 = components.password ? password.fromForm(data) : {};
    const changePassword2 = components.changePassword ? changePassword.fromForm(data) : {};
    const message2 = components.message ? message.fromForm(data) : {};
    return { ...contact2, ...shipping2, ...billing2, ...password2, ...changePassword2, ...message2 };
}

export function toForm(data) {
    const contact2 = contact.toForm(data);
    const shipping2 = shipping.toForm(data);
    const billing2 = billing.toForm(data);
    const card2 = card.toForm(data);
    const password2 = password.toForm(data);
    const changePassword2 = changePassword.toForm(data);

    return { ...contact2, ...shipping2, ...card2, ...billing2, ...password2, ...changePassword2 };
}

export default {
    fromForm,
    toForm,
    contact,
    card,
    shipping,
    billing,
    password,
    changePassword
}
