import React, {Component} from 'react';
import logo from "../logo-white.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {NavLink, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";

@inject('formStore')
@withRouter
@observer
class Footer extends Component {
    render() {
        const loggedIn = (localStorage.getItem('isLoggedIn') === "true");
        return (
            <div className="Footer" >
                {/* <MailChimpForm/> */}
                <div className="Footer-bottom-section">
                    <div className="Footer-nav-div ">
                        <div className="Footer-branding-div">
                            <img src={ logo } className="Header-app-logo" alt="logo" />
                            <div className="Footer-social-div">
                                <a href="https://www.facebook.com/GroPod/"><FontAwesomeIcon icon={faFacebookSquare} className="Footer-social-icon"  /></a>
                                <a href="https://www.instagram.com/gropod/"><FontAwesomeIcon icon={faInstagram} className="Footer-social-icon"  /></a>
                                <a href="https://twitter.com/GroPod"><FontAwesomeIcon icon={faTwitter} className="Footer-social-icon"  /></a>
                            </div>
                        </div>
                        {/* <div className="Footer-navlink-div Footer-info-div">
                            <p className="Footer-cat-label">
                                INFORMATION
                            </p>
                            <NavLink exact={true} to='/aboutus' activeClassName="Footer-nav-active" className="Footer-nav-link">
                                <p className="Text-standard-font-footer" >About Us</p>
                            </NavLink>
                            <NavLink exact={true} to='/faq' activeClassName="Footer-nav-active" className="Footer-nav-link">
                                <p className="Text-standard-font-footer">FAQ</p>
                            </NavLink>
                            <NavLink exact={true} to='/privacypolicy' activeClassName="Footer-nav-active" className="Footer-nav-link">
                                <p className="Text-standard-font-footer">Privacy Policy</p>
                            </NavLink>
                            <NavLink exact={true} to='/termsandconditions' activeClassName="Footer-nav-active" className="Footer-nav-link">
                                <p className="Text-standard-font-footer">Terms & Conditions</p>
                            </NavLink>
                        </div> */}
                        <div className="Footer-navlink-div Footer-account-div">
                            <p className="Footer-cat-label">
                                MY ACCOUNT
                            </p>
                            {loggedIn ?
                                <div className="Footer-nav-link" onClick={()=>this.props.formStore.onLogout(this.props.history)}>
                                    <p className="Text-standard-font-footer">Logout</p>
                                </div>
                                :
                                <div>
                                    <NavLink exact={true} to='/login' activeClassName="Footer-nav-active" className="Footer-nav-link">
                                        <p className="Text-standard-font-footer">Login</p>
                                    </NavLink>
                                    <NavLink exact={true} to='/register' activeClassName="Footer-nav-active" className="Footer-nav-link">
                                        <p className="Text-standard-font-footer">Register</p>
                                    </NavLink>
                                </div>
                            }
                            <NavLink exact={true} to='/shoppingcart' activeClassName="Footer-nav-active" className="Footer-nav-link">
                                <p className="Text-standard-font-footer">Cart</p>
                            </NavLink>
                            <NavLink exact={true} to='/checkout' activeClassName="Footer-nav-active" className="Footer-nav-link">
                                <p className="Text-standard-font-footer">Checkout</p>
                            </NavLink>
                        </div>
                        <div className="Footer-navlink-div Footer-about-div">
                            <p className="Footer-cat-label">
                                ABOUT US
                            </p>
                            <p className="Text-standard-font-footer">
                            We believe everyone should have access to Pure Produce&trade; anywhere in the world, at any time of the year. Our products are engineered to optimize plant growth, minimize energy consumption, reduce water usage, and enhance taste. Join our food revolution by becoming your own sustainable farmer and taking control of your food.
                            </p>
                        </div>
                    </div>
                    <div className="Footer-lime-hr" />
                    <div className="Footer-copyright">
                        <p className="Footer-copywright-text">©{new Date().getFullYear()} HELIPONIX™. All Right Reserved.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
