import alertStore from "../stores/AlertStore";
import moment from 'moment';

export function checkForErrors(returnData, successText, errorText, noAlert) {
    const returnErrors = {
        hasErrors: false,
        message: "",
        errors: {},
    };

    let successMessage;
    let errorMessage;
    let showAlert = true;

    if (successText) {
        successMessage = successText;
    }

    if (errorText) {
        errorMessage = errorText;
    }

    if (noAlert) {
        showAlert = false;
    }
    if (returnData.httpStatus === 200) {
        if (showAlert) {
            alertStore.setMessage({ type: 'success', text: successMessage });
        }
        return returnErrors;

    } else if (returnData.httpStatus === 400) {

        if (returnData.hasOwnProperty('error')) {
            returnErrors.hasErrors = true;
            returnErrors.message = returnData.message;
            for (let key of returnData.validation.keys) {
                returnErrors.errors[key] = errorMessages[key] || "This field is required";
            }
            // the following is a really horrible way to handle these errors but it'll do for now
            if (returnData.message === `child "oldpassword" fails because ["oldpassword" length must be at least 8 characters long]`)
                returnErrors.errors['oldpassword'] = 'Password length must be at least 8 characters long'
            if (returnData.message === `child "newpassword" fails because ["newpassword" length must be at least 8 characters long]`)
                returnErrors.errors['newpassword'] = 'New password length must be at least 8 characters long'
            if (showAlert) {
                alertStore.setMessage({ type: 'danger', text: errorMessage });
            }
            return returnErrors;
        }

    } else if (returnData.httpStatus === 500) {
        returnErrors.hasErrors = true;
        returnErrors.message = returnData.message;
        if (showAlert) {
            alertStore.setMessage({ type: 'danger', text: errorMessage });
        }
        return returnErrors;
    } else {
        returnErrors.hasErrors = true
        returnErrors.message = returnData.messsage
        console.log(`message: ${returnData.message}`)
        if (returnData.message === 'Your password confirmation did not match') {
            returnErrors.errors['newpassword'] = 'Your password confirmation did not match'
        }
        if (returnData.message === 'The old password is incorrect')
            returnErrors.errors['oldpassword'] = 'The old password is incorrect'
        if (returnData.message === `The email address is already registered`)
            returnErrors.errors['email'] = 'Email already registered'
        if (returnData.error === `Your password confirmation did not match`)
            returnErrors.errors['password'] = 'Passwords do not match'
        if (returnData.message === 'It is too late to modify this subscription.')
            alertStore.setMessage({ type: 'danger', text: "Your subscription is about to ship. Please update your subscription after it has shipped. If you need assistance, please contact support." })
        else
            alertStore.setMessage({ type: 'danger', text: errorMessage });
        return returnErrors;
    }
}

const errorMessages = {
    password: 'Password must be at least 8 character long',
};

export function calcFilledSeeds(myPkg, myCustomSeeds) {
    let total = 0;
    for (const key of Object.keys(myPkg)) {
        if (myPkg[key].included) {
            for (let item of myPkg[key].included) {
                const baseQty = parseInt(item.qty);
                total += baseQty * myPkg[key].qty.value;
            }
        }
    }
    for (const key of Object.keys(myCustomSeeds)) {
        total += parseInt(myCustomSeeds[key].value);
    }
    return total;
}

/**
 * Helper function for rendering
 *
 * @param  string   date          ISO formated date with timezone
 * @param  string   format        format to display
 * @return string   display text
 */
export function formatDate(date, format = 'MM/DD/YY') {
    // If we don't have a null or empty date
    if (date) {
        const momentDate = moment(date);

        // If the date is valid
        if (momentDate.isValid()) {
            return momentDate.format(format);
        }
    }

    return 'n/a';
}

export function checkNextShipment(date) {
    // If we don't have a null or empty date
    if (date) {
        const diff = moment().diff(date, 'days');
        return diff > 28;
    }

    return null;
}

export function hasPassedDate(date) {
    // If we don't have a null or empty date
    if (date) {
        return moment().diff(date, 'minutes');
    }

    return null;
}

export function lessThanOneDay(date) {
    // If we don't have a null or empty date
    if (date) {
        const diff = moment().diff(date, 'hours');
        return diff < 24;
    }

    return null;
}

export function stopPropagation(event) {
    const _event = event ? event : window.event;

    _event.cancelBubble = true;
    if (_event.stopPropagation) {
        _event.stopPropagation()
    }
}
