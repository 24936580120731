import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import DropdownSelect from "../../components/forms/DropdownSelect";
import SubscriptionCart from "./SubscriptionCart";

@inject('subscriptionStore', 'seedStore')
@observer
class SubscriptionPackage extends Component {
    componentWillMount() {
        if (this.props.seedStore.sizes.length === 0) {
            this.props.seedStore.getSizes();
        }
    }

    render() {
        if(this.props.seedStore.sizes.length === 0) {
            return null
        }

        const mySize = this.props.seedStore.mySize;
        
        return (
            <div>
                <div className="Select-package-div">
                    <div className="ChooseAmount-package-upper-div">
                        <div className="ChooseAmount-circle-div">
                            <p className="Text-large-white">{`${mySize.qty}`}</p>
                            <p className="Text-white-robo-reg">PACK</p>
                        </div>
                        <p className="Text-robo-med">{`${mySize.qty} Gropods`}</p>
                    </div>
                    <div className="ChooseAmount-package-lower-div" style={{paddingTop:24}}>
                        <p className="ChooseAmount-description-text">{`${mySize.description || ""}`}</p>

                        <p className="Text-black-robo Select-price-text">{`$${mySize.price}`}</p>
                        {mySize.savings > 0 && <p className="Text-robo-reg-blue">{`${mySize.savings}% Savings`}</p>}
                        <DropdownSelect
                            size="w-100"
                            name="mySize"
                            placeholder="Select Size"
                            optionsKey="sizeOptions"
                            value={this.props.seedStore.selectedSize}
                            onChange={(name, input)=> this.props.seedStore.onChangeSize(name, input)}
                        />
                        <div className="d-none">
                            <DropdownSelect
                                size="w-100"
                                name="myFreq"
                                placeholder="Select Frequency"
                                optionsKey="frequencyOptions"
                                value={this.props.seedStore.selectedFreq}
                                onChange={(name, input)=> this.props.seedStore.onChangeFreq(name, input)}
                            />
                        </div>
                    </div>
                </div>
                <SubscriptionCart/>
            </div>
        );
    }
}

export default SubscriptionPackage;
