import React, { Component } from 'react';
import Tutorials from "../../Support/Tutorials";
import AccountWidget from "../../../components/forms/accountWidget/Index";
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tutorial: tutorials[1]
        };
    }

    onClick(tutorial) {
        this.setState({tutorial: tutorial})
    }

    render() {
        return (
            <div className="Help-contents">
                <p className="Text-robo-med">Getting Started</p>
                <div className="Help-video-div">
                    <iframe 
                        title="Tutorial Video"
                        className="Help-video" src={this.state.tutorial.link} frameBorder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                    />
                </div>
                <Tutorials onClick={this.onClick.bind(this)} tutorials={tutorials} activeTutorial={this.state.tutorial}/>
                <p className="Text-robo-med Help-category-title mb-4">Contact Us</p>
                <AccountWidget Contact noHeader Message buttonLabel="Send" onClick={(e)=>this.props.formStore.onContact(e)}/>
            </div>
        );
    }
}

export default Support;

const tutorials = [
    {
        title: "Setting up the GroPod",
        category: 1,
        link: "https://www.youtube.com/embed/FQHhuMnF45o",
        description: "This video outlines the setup process for the GroPod."
    },
    {
        title: "Connect your GroPod to Wifi",
        category: 1,
        link: "https://www.youtube.com/embed/Ts_vmFDwLIE",
        description: "This video outlines the WiFi connection steps for the GroPod."
    },
    {
        title: "How to subscribe for G-Pods",
        category: 1,
        link: "https://www.youtube.com/embed/OQ0GgvIZEyI",
        description: "This video outlines the subscription enrollment process to receive monthly shipments of G-Pods."
    },
    {
        title: "How to change or update payment method",
        category: 2,
        link: "https://www.youtube.com/embed/Lqa8U1gtc3k",
        description: "How to change or update the account payment information."
    },
    {
        title: "How to Change Account Details",
        category: 2,
        link: "https://www.youtube.com/embed/Fx7g40AEHFk",
        description: "How to change the GroPod account details such as phone number, shipping address, email, etc."
    },
    {
        title: "Cleaning Instructional Video",
        category: 2,
        link: "https://youtu.be/BYNBk9lz4sc",
        description: "This video outlines the cleaning process for the GroPod© Smart Garden."
    }
];
