import { observable, action, computed } from 'mobx';
import routes from "../utils/ApiRoutes";
import appFetch from "../utils/AppFetch";
import { calcFilledSeeds, lessThanOneDay } from "../utils/UtilityFunctions";
import dropdownStore from "./DropdownStore";
import UserStore from "./UserStore";
import subscriptionStore from "./SubscriptionStore";

class SeedStore {
    @observable isLoading = false;
    @observable activeTab = 1;
    @observable activeSubTab = 1;
    @observable sizes = [];
    @observable packages = [];
    @observable seeds = [];
    @observable searchResults = [];
    @observable mySize = {};
    @observable selectedSize = null;
    @observable selectedFreq = dropdownStore.frequencyOptions[1];
    @observable myPkg = {};
    @observable myCustomSeeds = {};
    @observable selectedSeeds = {};
    @observable filledSeeds = 0;
    @observable showFooterPanel = false;

    @action setStorage() {
        const date = new Date();
        localStorage.setItem('mySize', JSON.stringify(this.mySize));
        localStorage.setItem('myPkg', JSON.stringify(this.myPkg));
        localStorage.setItem('myCustomSeeds', JSON.stringify(this.myCustomSeeds));
        localStorage.setItem('date', JSON.stringify(date))
    }

    @action setActiveTab(tab) {
        this.activeTab = tab;

        if (tab === 2) {
            this.setActiveSubTab(1)
        }
    }

    @action setActiveSubTab(tab) {
        this.activeSubTab = tab;
    }

    @action async getSizes() {
        this.isLoading = true;
        const api = routes.subscription.getSizes();
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.sizes = returnData;
            this.isLoading = false;
            if (!UserStore.authUser.subscription) {
                this.mySize = returnData[0];
            }
        }
    }

    @action async getPackages() {
        this.isLoading = true;
        const api = routes.subscription.getPackages();
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.packages = returnData;
            this.isLoading = false;
        }
    }

    @action async getSeeds() {
        this.isLoading = true;
        const api = routes.subscription.getSeadpod();
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.seeds = returnData;
            this.isLoading = false;
        }
    }

    @action onChangeSize(name, input, history) {
        for (let size of this.sizes) {
            if (size.id === input.value) {
                this.mySize = size;
                this.selectedSize = input;
                this.activeTab = 2;
            }
        }
        this.setStorage();
        history && history.push('gropods/selectseeds')
    }

    @action onChangeFreq(name, input) {
        this.selectedFreq = input;
    }

    @action addPackage(pack, input) {
        const oldpkg = this.myPkg[pack.name];
        let success = false;
        do {
            this.myPkg[pack.name] = pack;
            this.myPkg[pack.name].qty = input;
            const total = calcFilledSeeds(this.myPkg, this.myCustomSeeds);
            this.filledSeeds = total;
            success = this.checkFilledSeeds(total);
            if (!success) {
                input.value--;
                input.label = `${input.value}`;
            }

        } while (input.value > 0 && !success)

        if (!success) {
            if (oldpkg === undefined) {
                delete this.myPkg[pack.name];
            } else {
                this.myPkg[pack.name] = oldpkg;
            }
            const total = calcFilledSeeds(this.myPkg, this.myCustomSeeds);
            this.filledSeeds = total;
        }

        this.setStorage();
    }

    @action addSeeds(name, input) {
        const oldseed = this.myCustomSeeds[name];
        let success = false;
        do {
            this.myCustomSeeds[name] = input;
            const total = calcFilledSeeds(this.myPkg, this.myCustomSeeds);
            this.filledSeeds = total;
            success = this.checkFilledSeeds(total);
            if (!success) {
                input.value--;
                input.label = `${input.value} Pods`;
            }
        } while (input.value > 0 && !success)

        if (!success) {
            if (oldseed === undefined) {
                delete this.myCustomSeeds[name];
            } else {
                this.myCustomSeeds[name] = oldseed;
            }
            const total = calcFilledSeeds(this.myPkg, this.myCustomSeeds);
            this.filledSeeds = total;
        }

        this.setStorage();
    }

    // updateSeeds
    @action updateSeeds(name) {

        if (name && this.myCustomSeeds) {
            const currentSeedValue = this.myCustomSeeds[name].value ? this.myCustomSeeds[name].value : 0;

            this.filledSeeds = this.filledSeeds - currentSeedValue;

            delete this.myCustomSeeds[name];

        } else {
            this.myCustomSeeds = {}
            this.myPkg = {}
            this.filledSeeds = 0;
        }

        this.setStorage();
    }

    @action checkFilledSeeds(total) {
        if (total > this.mySize.qty && total <= 60) {
            this.showFooterPanel = true;
            const nextLargestSize = this.sizes.filter((size) => {
                return size.qty >= total ? size : null
            });
            if (nextLargestSize.length > 0) {
                this.mySize = nextLargestSize[0];
                this.selectedSize = { value: nextLargestSize[0].id, label: nextLargestSize[0].name };
            }
            return true;
        } else {
            return total <= 60;
        }
    }

    @action async addSubscribedSeeds(mySubscription) {
        this.mySize = mySubscription.subscription;
        this.selectedSize = { value: mySubscription.subscription.id, label: mySubscription.subscription.name };
        for (let seed of mySubscription.seedpods) {
            this.myCustomSeeds[seed.name] = { label: seed.qty + " Pods", value: seed.qty };
        }
        this.filledSeeds = calcFilledSeeds(this.myPkg, this.myCustomSeeds);
    }

    @action async getSeedCart() {
        const dateIsValid = lessThanOneDay(JSON.parse(localStorage.getItem('date')));

        if (dateIsValid) {
            this.mySize = JSON.parse(localStorage.getItem('mySize'));
            this.selectedSize = { value: this.mySize.id, label: this.mySize.name };
            this.myPkg = JSON.parse(localStorage.getItem('myPkg'));
            this.myCustomSeeds = JSON.parse(localStorage.getItem('myCustomSeeds'));
            this.filledSeeds = calcFilledSeeds(this.myPkg, this.myCustomSeeds);
        } else {
            const mySubscription = await subscriptionStore.getMySubscription();
            if (mySubscription.subscription) {
                this.addSubscribedSeeds(mySubscription);
            }
        }
    }

    @action hideFooterPanel() {
        this.showFooterPanel = false;
    }

    @computed get closeFooterPanelCountdown() {
        const showFooter = this.showFooterPanel;
        if (this.showFooterPanel) {
            const timer = setTimeout(() => { this.showFooterPanel = false }, 12000);
            return () => clearTimeout(timer);
        }
        return showFooter;
    }

    @computed get mySeedSubscription() {
        const mySeedsAll = {};

        // Copy myCustomSeeds onto mySeedsAll
        for (const key of Object.keys(this.myCustomSeeds)) {
            mySeedsAll[key] = parseInt(this.myCustomSeeds[key].value);
        }

        // Copy myPkg includes onto mySeedsAll
        for (const key of Object.keys(this.myPkg)) {
            if (this.myPkg[key].included) {
                for (let item of this.myPkg[key].included) {
                    const qty = item.qty * parseInt(this.myPkg[key].qty.value);
                    if (mySeedsAll[item.name]) {
                        mySeedsAll[item.name] = (mySeedsAll[item.name] + qty);
                    } else {
                        mySeedsAll[item.name] = qty;
                    }
                }
            }
        }

        // Map mySeeds all onto mySeedSubscription with format {id: qty}
        const keys = Object.keys(mySeedsAll);
        const mySeedSubscription = [];
        for (let seed of this.seeds) {
            if (keys.includes(seed.name)) {
                const pod = { id: seed.id, qty: mySeedsAll[seed.name] };
                mySeedSubscription.push(pod)
            }
        }
        return mySeedSubscription
    }

    @action searchSeeds(query) {
        if (query.length) {
            const searchResults = [];
            for (let seedPod of this.seeds) {
                const string = seedPod.name;
                const string2 = string.toLowerCase();
                if (string2.search(query) >= 0) {
                    searchResults.push(seedPod.id)
                }
            }
            this.searchResults = searchResults;
        } else {
            this.searchResults = [];
        }
    }
}

export default new SeedStore();
