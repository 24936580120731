import React, { Component } from 'react';
import AccountWidget from "../../../components/forms/accountWidget/Index";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import SubscriptionPackage from "../SubscriptionPackage";
import {ButtonGrey} from "../../../components/Buttons";
import {injectStripe} from "react-stripe-elements";

@inject('formStore', 'userStore', 'seedStore')
@withRouter
@observer
class Checkout extends Component {
    onClickBack() {
        this.props.history.push('/gropods/selectseeds');
        this.props.seedStore.setActiveTab(2);
    }
    
    async onSubmit(e) {
        e.preventDefault()
        if (this.props.userStore.authUser.subscription) {
            this.props.formStore.onUpdateSubscription(this.props.history)
        } else if (this.props.formStore.useSavedToken) {
            this.props.formStore.onSubmitSubscription(this.props.history);
        } else {
            const token = await this.saveToken();
            if(token) {
                this.props.formStore.onSubmitSubscription(this.props.history);
            }
        }
    }

    async saveToken() {
        const {token} = await this.props.stripe.createToken({
            name: this.props.formStore.form.nameOnCard,
            // eslint-disable-next-line
            address_zip: this.props.formStore.form.paymentZip
        });

        if (token) {
            this.props.formStore.setPaymentToken(token);
        }
        
        return token;
    }
    
    render() {
        document.title = 'Gropod Subscription - GroPod'
        const btnText = this.props.userStore.authUser.subscription? "Update Order" : "Place Order";
        const isLoggedIn = localStorage.getItem('isLoggedIn') === "true";
        return (
            <div className="PreSelect-row">
                <div className="Seed-checkout-left mr-4 mb-5">
                    <div className="Account-form-div">
                        <AccountWidget
                            Contact
                            NewPassword={!isLoggedIn}
                            Shipping
                            Card
                            Billing
                            smallForm={true}
                            SubscriptionCheckout
                            buttonLabel={btnText}
                            buttonClass={"float-left mr-3 mt-2"}
                            updatePayment={false}
                            onClick={(e)=>this.onSubmit(e)} />
                        <ButtonGrey
                            onClick={()=> this.onClickBack()}
                            className="Seedpods-cart-back-button float-left mt-2"
                            label={"Back"}
                        />
                    </div>
                </div>
                <div className="PreSelect-right">
                    <SubscriptionPackage/>
                </div>
            </div>
        );
    }
}

export default injectStripe(Checkout);
