import { observable, action } from 'mobx';
import appFetch from '../utils/AppFetch'
import routes from '../utils/ApiRoutes'
import transform from '../transformers/UserTransformer'
import { checkForErrors } from '../utils/UtilityFunctions'
import SeedStore from '../stores/SeedStore'
import ProductStore from '../stores/ProductStore'
import alertStore from "./AlertStore";
import cartStore from "./CartStore";
import userStore from './UserStore'

class FormStore {
    @observable form = {};
    @observable errors = {};
    @observable shipments = {};
    @observable sizes = {};
    @observable mySubscriptions = {};
    @observable newSubscription = {};
    @observable useSavedToken = true;
    @observable updatingSubscription = false;

    @action onChangeInput(input) {
        const name = input.target.name;
        const value = input.target.type === 'checkbox' ? input.target.checked : input.target.value;
        this.form[name] = value
    }

    @action onChangeRadio(input) {
        this.useSavedToken = input.target.id === "saved";
    }

    @action clearForm() {
        this.form = {};
    }

    @action async applyPromo() {
        if (this.form.promo) {
            cartStore.cart = await appFetch(`cart?promo=${this.form.promo}`);
            alertStore.setMessage({ type: 'success', text: "Promotion applied" });
        }
    }

    @action onCheckBillSameAsShip(input) {
        const checked = input.target.checked;
        this.form[input.target.name] = checked;

        this.form.billingAddress1 = checked ? this.form.shippingAddress1 : "";
        this.form.billingAddress2 = checked ? this.form.shippingAddress2 : "";
        this.form.billingCity = checked ? this.form.shippingCity : "";
        this.form.billingState = checked ? this.form.shippingState : null;
        this.form.billingZip = checked ? this.form.shippingZip : "";
    }

    @action onChangePassword(input) {
        const checked = input.target.checked;
        this.form[input.target.name] = checked;
    }

    @action onChangeDropdown(name, input) {
        this.form[name] = input;
    }

    @action async onLogout(history) {
        const returnData = await appFetch("logout", {}, "POST");
        if (returnData.httpStatus === 200) {
            localStorage.setItem('isLoggedIn', false);
            localStorage.removeItem('token');
            localStorage.removeItem('mySize');
            localStorage.removeItem('myPkg');
            localStorage.removeItem('myCustomSeeds');
            localStorage.removeItem('date');
            history && history.push('/login');
            this.clearForm();
            cartStore.clearCart();
        }
    }

    @action async onLogin(e, history) {
        e.preventDefault();
        const formData = {
            email: this.form.email,
            password: this.form.password,
        };

        const api = routes.user.login();
        const returnData = await appFetch(api.url, formData, api.method);

        const returnErrors = checkForErrors(returnData,
            "Successfully logged in!",
            "Error: Your email or password did not match our records.");
        this.errors = returnErrors.errors;

        if (returnData.httpStatus === 200) {
            localStorage.setItem('isLoggedIn', true);
            localStorage.setItem('token', returnData.token);
            history && history.push('/myAccount');
        }
    }

    @action async onRegister(e, history) {
        e.preventDefault();
        const components = { contact: true, shipping: true, password: true };
        const formData = transform.fromForm(this.form, components);

        const api = routes.user.newUser();
        const returnData = await appFetch(api.url, formData, api.method);

        const returnErrors = checkForErrors(returnData);
        this.errors = returnErrors.errors;

        if (returnData.httpStatus === 200) {
            localStorage.setItem('isLoggedIn', true);
            history && history.push('/myAccount')
        }
    }

    @action async onUpdateSubscription(history) {
        const components = { contact: true, card: true, shipping: true, billing: true }
        const formData = transform.fromForm(this.form, components)

        formData["paymenttoken"] = this.form.paymentToken ? this.form.paymentToken.id : "NA";
        formData["usesavedtoken"] = this.useSavedToken || false;
        formData["subscriptionsize"] = SeedStore.mySize.id;
        formData["seedpods"] = SeedStore.mySeedSubscription;

        const api = routes.subscription.updateSubscription();
        const returnData = await appFetch(api.url, formData, api.method);

        const returnErrors = checkForErrors(
            returnData,
            "Your subscription has been saved.",
            "Error: Your subscription could not be updated.");
        this.errors = returnErrors.errors;

        if (!returnErrors.hasErrors) {
            history.push('/myaccount/subscription')
        }
    }

    @action async onSubmitSubscription(history) {
        const components = { contact: true, card: true, shipping: true, billing: true };
        const formData = transform.fromForm(this.form, components);

        if (this.form.newPassword) {
            formData["password"] = this.form.newPassword;
            formData["password2"] = this.form.confirmPassword;
        }

        formData["paymenttoken"] = this.form.paymentToken ? this.form.paymentToken.id : "NA";
        formData["usesavedtoken"] = this.useSavedToken || false;
        formData["subscriptionsize"] = SeedStore.mySize.id;
        formData["seedpods"] = SeedStore.mySeedSubscription;

        const api = routes.subscription.newSubscription();
        const returnData = await appFetch(api.url, formData, api.method);

        const returnErrors = checkForErrors(
            returnData,
            "Your subscription has been saved",
            "Error: Your subscription could not be completed");
        this.errors = returnErrors.errors;

        if (!returnErrors.hasErrors) {
            if (this.updatingSubscription) {
                this.updatingSubscription = false;
                history.push('/myaccount/subscription')
            } else {
                this.form.effectiveDate = returnData.effectiveDate;
                history.push('/gropods/success')
            }
        }
    }

    @action async onCheckout(history) {
        console.log('history', history)
        const components = { contact: true, shipping: true, card: true, billing: true };

        const formData = transform.fromForm(this.form, components);

        if (this.form.promo) {
            formData["promo"] = this.form.promo;
        }

        if (this.form.newPassword) {
            formData["password"] = this.form.newPassword;
            formData["password2"] = this.form.confirmPassword;
        }

        formData["paymenttoken"] = this.form.paymentToken ? this.form.paymentToken.id : "NA";
        formData["usesavedtoken"] = this.useSavedToken || false;
        formData["updatestoredpayment"] = this.form.updateStoredPayment || false;

        const api = routes.cart.checkout();
        console.log('formData', formData)
        const returnData = await appFetch(api.url, formData, api.method);

        const returnErrors = checkForErrors(
            returnData,
            returnData.message,
            returnData.message);
        this.errors = returnErrors.errors;

        if (returnData.httpStatus === 200) {
            history.push('/checkout/ordersuccess');
            cartStore.getCart();
        }
    }

    @action async onUpdateMyAccount(e, history) {
        e.preventDefault();
        const components = { contact: true, shipping: true, card: true, billing: true, changePassword: true };
        const formData = transform.fromForm(this.form, components);

        formData["paymenttoken"] = this.form.paymentToken ? this.form.paymentToken.id : "NA";
        formData["usesavedtoken"] = this.useSavedToken || false;
        formData["updatestoredpayment"] = this.form.updateStoredPayment || false;
        formData["changePassword"] = this.form.changePassword || false;
        console.log('formData', formData)
        const api = routes.user.editUser();
        
        const returnData = await appFetch(api.url, formData, api.method);

        const returnErrors = checkForErrors(returnData);
        this.errors = returnErrors.errors;
        if (history && !returnErrors.hasErrors) {
            await userStore.getUser()
            history.push('/myaccount');
            this.form.changePassword = false
            this.form.newPassword = ''
            this.form.confirmPassword = ''
            this.form.password = ''
            this.form.oldPassword = ''
        }
    }

    @action async onContact(e) {
        const components = { contact: true, message: true };
        const formData = transform.fromForm(this.form, components);

        formData.name = formData.first + formData.last;
        delete formData.first;
        delete formData.last;
        e.preventDefault();

        const api = routes.contact.contactUs();
        const returnData = await appFetch(api.url, formData, api.method);
        const returnErrors = checkForErrors(
            returnData,
            "Your message has been sent!",
            "Error: Your message could not be sent");
        this.errors = returnErrors.errors;
    }

    /**
     * forgot password request
     */
    @action async onForgotPassword(e) {
        e.preventDefault();
        const components = { contact: true, password: true };
        const formData = transform.fromForm(this.form, components);

        const api = routes.user.forgotPasswordRequest();
        const returnData = await appFetch(api.url, formData, api.method);
        const returnErrors = checkForErrors(
            returnData,
            "Reset password email has been sent!",
            "Error: Your forgot password request could not be submitted");
        this.errors = returnErrors.errors;
    }

    /**
     * on reset password request
     */
    @action async onResetPassword(e, passwordResetInfo, history) {
        e.preventDefault();

        const components = { password: true };
        const formData = transform.fromForm(this.form, components);

        formData["resetToken"] = passwordResetInfo.token;
        formData["userId"] = passwordResetInfo.userId;

        const api = routes.user.resetPassword();
        const returnData = await appFetch(api.url, formData, api.method);
        const returnErrors = checkForErrors(
            returnData,
            "Password updated successfully!",
            "Error: Your password reset request could not be submitted");
        this.errors = returnErrors.errors;
        if (returnData.httpStatus === 200) {
            history && history.push('/login');
        }
    }

    @action async onSubmitReview(e, id, history) {
        e.preventDefault();
        const formData = {
            title: this.form.reviewTitle,
            review: this.form.reviewMessage,
            rating: this.form.reviewRating ? this.form.reviewRating : 5,
        };

        const api = routes.products.reviewProduct(id);
        const returnData = await appFetch(api.url, formData, api.method);

        const returnErrors = checkForErrors(returnData,
            "Your review has been sent!",
            "Error: Your review could not be submitted");
        this.errors = returnErrors.errors;

        if (!returnErrors.errors.hasErrors) {
            ProductStore.getProduct(id);
            this.form.reviewRating = 5;
            this.form.reviewTitle = "";
            this.form.reviewMessage = "";
            history.push(`/products/product/reviewsent`)
        }
    }

    @action async setPaymentToken(token) {
        this.form.paymentToken = token;
    }
}

export default new FormStore();
