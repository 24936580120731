import React, {Component} from 'react';
import {ButtonGrey, ButtonWhite} from "../../../components/Buttons";
import {withRouter} from "react-router-dom";
import {checkNextShipment, formatDate, hasPassedDate} from "../../../utils/UtilityFunctions";
import {inject, observer} from "mobx-react";
import EmptyMessage from "../../../components/EmptyMessage";
import Tooltip from "../../../components/Tooltip";
import CancelModal from "../../../components/modals/CancelModal";

@inject('subscriptionStore', 'formStore')
@withRouter
@observer
class Subscription extends Component {

    componentWillMount() {
        this.props.subscriptionStore.getMySubscription();
    }

    renderSeedpods() {
        return this.props.subscriptionStore.mySubscription.seedpods.map((seedpod, index) => {
            return (
                <div key={index} className="row my-2">
                    <div className="col-4 Text-robo-sm">{seedpod.name}</div>
                    <div className="col-4 Text-robo-sm text-right">{seedpod.qty}</div>
                    <div className="col-4 text-right"/>
                </div>
            )
        })
    }
    
    skipSubscription(sub) {
        if(this.props.subscriptionStore.skipNext || checkNextShipment(sub.next) ) {
            return <div className="Round-button-text-grey">Shipment Skipped</div>
        } else {
            return (
                <div className={hasPassedDate(sub.modifyuntil)? "d-none": ""}>
                    <Tooltip
                        modifyUntil={formatDate(sub.modifyuntil)}
                        button={
                            <ButtonGrey
                                className="Subscription-skip-button Subscription-control"
                                label="Skip Next Shipment"
                                onClick={() => this.props.subscriptionStore.skipSubscription()}
                            />
                        }
                    />
                </div>
            )
        }
    }
    
    updateSubscription() {
        this.props.formStore.updatingSubscription = true;
        this.props.history.push("/gropods/selectseeds");           
    }

    renderSubscriptionItems() {
        if (this.props.subscriptionStore.mySubscription.subscription) {
            const sub = this.props.subscriptionStore.mySubscription;
            return (
                <div>
                    <div className="Subscription-item-div">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <h2 className="Text-robo-med">{`${sub.subscription.qty} Seed Pods Package`}</h2>
                                <p className="Text-robo-reg-small">{`Recieve ${sub.subscription.qty} pods every ${sub.frequency} weeks`}</p>
                                <p className="Text-robo-reg-small-green my-3">
                                    {`Next shipment on ${formatDate(sub.next)}`}
                                </p>
                            </div>
                            <div className="col-auto">
                                <div className="row justify-content-start justify-content-xl-end">
                                    <div className="col-auto order-xl-last">
                                        <ButtonGrey
                                            className="mb-4"
                                            label="Update"
                                            onClick={() => this.updateSubscription()}
                                        />
                                    </div>
                                    <div className="col-auto order-xl-first">
                                        <CancelModal/>
                                    </div>
                                </div>
                                <div className="row justify-content-start justify-content-xl-end">
                                    <div className="col-auto">
                                        {this.skipSubscription(sub)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-4 border-bottom">
                        <div className="row mb-4">
                            <div className="col-4">Product</div>
                            <div className="col-4 text-right">Qty</div>
                            <div className="col-4 text-right">Price</div>
                        </div>
                        {this.renderSeedpods()}
                    </div>
                    <div className="py-4">
                        <div className="row mb-">
                            <div className="col-4 Text-robo-sm">Total</div>
                            <div className="col-4 Text-robo-sm text-right">{sub.subscription.qty}</div>
                            <div className="col-4 Text-robo-sm text-right">{sub.subscription.price}</div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <EmptyMessage message="You have no current subscription.">
                    <ButtonWhite
                        className="mx-auto mt-4"
                        label="Add Subscription"
                        onClick={() => this.props.history.push('/gropods')}
                    />
                </EmptyMessage>
            )
        }
    }

    render() {
        if (this.props.subscriptionStore.isLoading) {
            return <div className="alert alert-light">Loading</div>
        }

        return (
            <div className="Subscription-contents">
                {this.renderSubscriptionItems()}
            </div>
        );
    }
}

export default Subscription;
