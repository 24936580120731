import React, { Component } from 'react';
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";

import AccountWidget from "../../components/forms/accountWidget/Index";

@inject('formStore')
@withRouter
@observer
class Register extends Component {
    render() {
        return (
            <div className="Page-content">
                <div className="Account-title-div" style={{marginBottom: 43}}>
                    <h2 className="Text-title-large">Register</h2>
                </div>
                <AccountWidget 
                    Contact Shipping RegisterPassword buttonLabel="Register"
                    onClick={(e)=>this.props.formStore.onRegister(e, this.props.history)} 
                />
            </div>
        );
    }
}

export default Register;
