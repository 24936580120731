import React from "react"

const PageTitle = ({ title, subtitle }) => {

    const replaceHtmlInString = string => {
        const split = string.split('&trade;')
        return split.map(x => { return split.indexOf(x) === split.length-1 ? x : <>{x}&trade;</> })
    }

    return (
        <div className="Account-title-div" style={{marginTop: 60}}>
            <h2 className="Text-title-large mb-2">{replaceHtmlInString(title)}</h2>
            <h3 className="Text-subtitle">{subtitle}</h3>
        </div>
    )
}

export default PageTitle