import React, { Component } from 'react';
import AccountWidget from "../../../components/forms/accountWidget/Index";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";

@inject('cartStore')
@withRouter
@observer
class ShippingAddress extends Component {
    
    nextPage = () => {
        this.props.cartStore.setActiveTab(2);
        this.props.history.push('/checkout/paymentmethod');
    };

    render() {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === "true";
        return (
            <div className="Checkout-form-div">
                <AccountWidget 
                    Contact 
                    Shipping
                    NewPassword={!isLoggedIn}
                    buttonLabel="Next" 
                    buttonClass="Account-save-button" 
                    onClick={()=>{this.nextPage()}}
                />
            </div>
        );
    }
}

export default ShippingAddress;
