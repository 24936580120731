import React, {Component} from 'react';
import {Alert} from 'reactstrap';
import {inject, observer} from 'mobx-react';
import {CSSTransition} from "react-transition-group";

@inject('alertStore')
@observer
class AlertMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inProp: true,
        }
    }

    onDismiss() {
        this.props.alertStore.dismiss();
    }

    render() {
        return (
            <div>
                <CSSTransition
                    in={this.props.alertStore.visible}
                    timeout={500}
                    classNames="alert"
                    unmountOnExit
                >
                    <div className="alert-container">
                        <Alert
                            color={this.props.alertStore.message.type}
                            className={"alert-box"}
                            dismissible="true"
                            toggle={this.onDismiss.bind(this)}
                            fade={false}>
                            {this.props.alertStore.message.text}
                        </Alert>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default AlertMessages;
