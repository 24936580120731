import {observable, action} from 'mobx';
import appFetch from '../utils/AppFetch'
import routes from '../utils/ApiRoutes'

class ProductStore {
    @observable isLoading = false;
    @observable products = [];
    @observable product = {};

    @action async getProducts() {
        this.isLoading = true;
        const api = routes.products.getProducts();
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.products = returnData;
            this.isLoading = false;
        }
    }

    @action async getProduct(id) {
        this.isLoading = true;
        const api = routes.products.getProduct(id);
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.product = returnData;
            this.isLoading = false;
        }
    }
}

export default new ProductStore();
