import React, { Component } from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component {
    render() {
        // eslint-disable-next-line
        const {component: Component, path, ...rest} = this.props;
        const authorized = (localStorage.getItem('isLoggedIn') === "true");

        return (
            <Route
                {...rest} path={path}
                render={props =>
                    authorized ? <Component {...props} /> : <Redirect to={{pathname: "/login"}}/> 
                }
            />
        );
    }
}

export default withRouter(PrivateRoute)
