import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import DashView from "./DashView";
import EmptyMessage from "../../../components/EmptyMessage";

@inject('userStore')
@withRouter
@observer
class Dashboard extends Component {
    render() {

        if (!this.props.userStore.authUser.gropod) {
            return (
                <EmptyMessage message="No GroPod device synced to your account. Please contact support@gropod.io">
                </EmptyMessage>
            )
        } else {
            return <DashView gropod={this.props.userStore.authUser.gropod} />;
        }
    }
}

export default Dashboard;
