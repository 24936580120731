import React, {Component} from 'react';

export default class EmptyMessage extends Component {
    render() {
        return (
            <div className="Empty-message-box">
                <div className={this.props.className}>{this.props.message}</div>
                {this.props.children}
            </div>
        );
    }
}
