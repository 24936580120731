import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { ButtonSquare } from "../../components/Buttons";
const kitchen = require("../../assets/kitchen.jpg");
const lettuce = require("../../assets/gropod-harvest.jpg");
const gropod = require("../../assets/gropod-plants.png");
const gropodIcon = require("../../assets/gropod-icon.png");
const leafhand = require("../../assets/leafhand.png");
const wifi = require("../../assets/wifi.png");
const heroVideo = require("../../assets/hero-video.mp4");

@withRouter
class Home extends Component {
    render() {
        window.location.href = "https://gropod.io/"
        document.title = 'GroPod - Grow Pure Produce™ at Home'
        return (
            <div className="Home-content">
                <div className="video-container">
                    <div className="background-image" />
                    <video id="video" playsInline autoPlay muted loop src={heroVideo} type="video/mp4" />
                    <div className="video-content">
                        <div className="text-white video-header">Turn On. Tune In. Grow Out!&trade;</div>
                        <button
                            className="btn button-hero btn-lg btn-rounded mt-5"
                            onClick={() => { this.props.history.push('/products/gropod') }}>
                            <div className="p-2 mb-1">Learn More!</div>
                        </button>
                    </div>
                </div>
                <div className="Home-section Home-section-1" >
                    <div className="Home-1-left">
                        <h1 className="Text-title-large Home-margin-bottom">Grow Out Pure Produce&trade; at Home</h1>
                        <p className="Text-open-light-22">
                            The GroPod is a smart garden which allows homeowners to grow their own
    vegetables and herbs right in their own kitchen. The automated aeroponic system combined with smart,
    cloud software allows anyone to turn their kitchen into a farm regardless of climate, space, or
    knowledge of agriculture. Through the customized monthly Seed Pod subscription, you can guarantee
    your access to Pure Produce without ever traveling to the grocery store. Pure Produce is beneficial to
    your health and the environment as it is untouched by harmful chemicals and reduces water
    consumption and food waste by localizing food production.
                        </p>
                    </div>
                    <div className="Home-2-right">
                        <img src={kitchen} alt="" className="Home-apron-img" />
                    </div>
                </div>
                <div className="Home-section Home-section-2" >
                    <p className="Text-title-large Home-margin-bottom">GroPod makes growing Pure Produce&trade; at home easy</p>
                    <p className="Text-open-light-22">Using the GroPod is three easy steps: Turn on. Tune in. Grow out!&trade;, where the software fully automates the growing process so anyone can farm at home. The growing cabinet for the plants is fully self-contained with all necessary growing equipment included that just needs to be turned on.</p>
                    <div className="Home-2-row">
                        <div className="Home-2-row-section">
                            <p className="Text-small-title-black">Turn&nbsp;On.</p>
                            <img src={gropodIcon} alt="Turn On" className="Home-circle-img" />
                            <p>Install and turn on the appliance.</p>
                        </div>
                        <div className="Home-2-row-section">
                            <p className="Text-small-title-black">Tune&nbsp;In.</p>
                            <img src={wifi} alt="Tune In" className="Home-circle-img" />
                            <p>Connect to WiFi and select a G-Pod&trade; subscription</p>
                        </div>
                        <div className="Home-2-row-section">
                            <p className="Text-small-title-black">Grow&nbsp;Out!</p>
                            <img src={leafhand} alt="Grow Out!" className="Home-circle-img" />
                            <p>Plant the G-POD&trade; cartridges and harvest when you're craving.</p>
                        </div>
                    </div>
                    <ButtonSquare
                        className="Home-button"
                        label="Learn More"
                        onClick={() => { this.props.history.push('/products/gropod') }}
                    />
                </div>
                <div className="Home-section Home-section-3" >
                    <p className="Text-title-large Home-margin-bottom">Be Smart. Grow <span className="Text-robo-900-40px-lime">PURE</span>. Experience Fresh!</p>
                    <p className="Text-open-light-22">The GroPod can supply 2 full servings of leafy greens on a daily basis.</p>

                    <div className="Home-3-row">
                        <div>
                            <img src={gropod} alt="" className="Home-gropod-img" />
                        </div>
                        <div className="Home-3-list">
                            <div className="Home-3-list-item">
                                <p className="Text-small-title-black">Savory</p>
                                <div className="Green-accent Home-green-accent" />
                                <p>
                                    On average, the produce you buy at the store was harvested two weeks before your purchase.
    This results in the loss of most of its nutritional value. GroPod allows you to harvest your produce
    minutes before you incorporate it into a healthy meal ensuring you and your family receive maximum
    nutritional value.
                                </p>
                            </div>
                            <div className="Home-3-list-item">
                                <p className="Text-small-title-black">Sustainable</p>
                                <div className="Green-accent Home-green-accent" />
                                <p>
                                    Our patent-pending rotary aeroponic™ design uses 95% less water and half the energy
    consumption while tripling growth rates compared to traditional farming. Additionally, food waste is
    reduced since plants are kept alive until you’re ready to eat them.
                                </p>
                            </div>
                            <div className="Home-3-list-item">
                                <p className="Text-small-title-black">Smart</p>
                                <div className="Green-accent Home-green-accent" />
                                <p>
                                    The automated growing environment and Seed Pod subscription ensures that each plant
    receives the correct nutrients, water, and light without any significant amount of time or input from the
    user. It’s as simple as inserting the seed pods, updating the app, and harvest when ready!
                                </p>
                            </div>
                            <div className="Home-3-list-item">
                                <p className="Text-small-title-black">Safe</p>
                                <div className="Green-accent Home-green-accent" />
                                <p>
                                    Guaranteed NO preservatives, NO pesticides, NO fungicides, NO herbicides. You have control of your food now. The closed environment of the GroPod guarantees a safe growing environment free of harmful chemicals, so you can feel secure in knowing exactly where your food came from.
                                </p>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <ButtonSquare
                                    className="Home-button"
                                    label="Learn More!"
                                    onClick={() => { this.props.history.push('/products/gropod') }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Home-section Home-section-4" >
                    <p className="Text-title-large Home-margin-bottom">What can you grow in a GroPod?</p>
                    <p className="Text-open-light-22">
                        Grow daily harvests of FRESH leafy green vegetables, herbs, flowers, some fruiting plants, and dwarf varieties of specialty plants to pay for itself!
                    </p>
                    <ButtonSquare
                        className="Home-button Home-button-4"
                        label="Shop G-Pods"
                        onClick={() => { this.props.history.push('/gropods') }}
                    />
                    <img src={lettuce} alt="" className="Home-lettuce-img" />
                </div>
            </div>
        );
    }
}

export default Home;
