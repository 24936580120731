import React from 'react';
import Dashboard from "./Dashboard";
import Account from "./Account";
import Subscription from "./Subscription";
import OrderHistory from "./OrderHistory";
import OrderDetails from "./OrderHistory/OrderDetails";
import Help from "./Help";
import LiveView from "./Dashboard/LiveView";
import AccountTitles from "./AccountTitles";

export const routes = [
    {
        path: "/myaccount",
        exact: true,
        title: () => <AccountTitles type="dashboard"/>,
        main: () => <Dashboard/>
    },
    {
        path: "/myaccount/account",
        title: () => <AccountTitles type="myAccount"/>,
        main: () => <Account/>
    },
    {
        path: "/myaccount/subscription",
        title: () => <AccountTitles type="subscription"/>,
        main: () => <Subscription/>
    },
    {
        path: "/myaccount/orderhistory",
        exact: true,
        title: () => <AccountTitles type="orderHistory"/>,
        main: () => <OrderHistory/>
    },
    {
        path: "/myaccount/orderhistory/details",
        title: () => <AccountTitles type="orderHistory"/>,
        main: () => <OrderDetails/>
    },
    {
        path: "/myaccount/help",
        title: () => <AccountTitles type="help"/>,
        main: () => <Help/>
    },
    {
        path: "/myaccount/liveview",
        title: () => <AccountTitles type="liveView"/>,
        main: () => <LiveView/>
    },
    {
        path: "/myaccount/logout",
        exact: true,
        title: () => <AccountTitles type="logout"/>,
    }
];
