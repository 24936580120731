import React, {Component} from 'react';
import HeaderRow, {Title, Checkbox} from "../../HeaderRow";
import TextInput from "../TextInput2";
import Dropdown from "../DropdownSelect";
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class Billing extends Component {
    render() {
        const errors = this.props.formStore.errors;
        return (
            <div>
                <HeaderRow>
                    <Title text="Billing Address"/>
                    <Checkbox 
                        name="billSameAsShip" 
                        text="Same as shipping address" 
                        checked={this.props.formStore.form["billSameAsShip"]}
                        onChange={(input) => this.props.formStore.onCheckBillSameAsShip(input)} 
                    />
                </HeaderRow>
                <div className="row">
                    <TextInput name="billingAddress1" placeholder="Street Address 1" errors={errors["billing.address1"]} />
                    <TextInput name="billingAddress2" placeholder="Street Address 2" errors={errors["billing.address2"]} />
                </div>
                <div className="row">
                    <TextInput name="billingCity" placeholder="City" errors={errors["billing.city"]} />
                    <Dropdown name="billingState" placeholder="State" errors={errors["billing.state"]} size="col-6 col-lg-3" className="size-lg"/>
                    <TextInput name="billingZip" placeholder="Zip" errors={errors["billing.zip"]} size="col-6 col-lg-3"/>
                </div>
            </div>
        );
    }
}

export default Billing;
