import React, { Component } from 'react';
import TextInput from "../TextInput2";
import { inject, observer } from "mobx-react";
import { Title } from "../../HeaderRow";
import HeaderRow from "../../HeaderRow";
import { ButtonGreen } from "../../../components/Buttons";

@inject('formStore')
@observer
class ResetPassword extends Component {
    render() {
        const errors = this.props.formStore.errors;
        const submitButton = <ButtonGreen label={"Submit"} className={this.props.buttonClass} onClick={this.props.onClick} />;

        return (
            <form onSubmit={this.props.onSubmit}>
                <div>
                    <HeaderRow className={"align-items-end pb-1"}>
                        <Title text="Reset Password" />
                    </HeaderRow>
                    <div className="row">
                        <TextInput name="newPassword" placeholder="Password" type="password" errors={errors.password} />
                        <TextInput name="confirmPassword" placeholder="Confirm Password" type="password" errors={errors.password} />
                    </div>
                </div>
                {submitButton}
            </form>
        );
    }
}

export default ResetPassword;
