import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class ProductTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return (
            <div className="d-inline-block">
                <button onClick={this.toggle} style={{color: "#B7D552", border: 0, backgroundColor: "transparent", padding: 0, margin: 0 }}>Product Terms and Conditions</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" className={this.props.className}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody className="px-5 py-4">
                        <div className="Text-robo-med mb-4">Product Terms and Conditions</div>
                        <div className="">
                            <p className="Legal-title">Welcome to Heliponix, which creates automated aeroponic GroPod
                                appliances (&ldquo;Devices&rdquo;) and whose services are accessible through its website
                                (the &ldquo;Site&rdquo;) and via its mobile device application (the &ldquo;App&rdquo;).
                                Heliponix&#39;s mission is to allow the user to easily grow produce in the comfort of
                                their home, eliminating the dependence on the grocery store for most produce. </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <p className="Legal-title">General Use and Standards for Devices</p>

                                    <ol>
                                        <li>
                                            <p>Agreement to Terms<br/>
                                                By using the Device, the Site, and/or the App, you are agreeing to these
                                                terms and conditions (&ldquo;Terms&rdquo;) and our Privacy Policy. If
                                                you do not agree to these Terms and our Privacy Policy, do not use the
                                                Device, the Site, and/or the App.</p>
                                        </li>
                                        <li>
                                            <p>Changes to Terms and Conditions<br/>
                                                Heliponix may modify these Terms at any time, without prior notice.
                                                Heliponix may or may not notify you when changes, revisions, or
                                                termination are made to the Terms the Devices, the Site, or the App. All
                                                changes are effective immediately when Heliponix posts them, and they
                                                apply to all use of the Devices, Site, and/or App thereafter. If you
                                                continue to use the Devices, the Site, and/or the App after Heliponix
                                                has modified the Terms, you are indicating to Heliponix that you agree
                                                to be bound by the modified Terms. If you do not agree to be bound by
                                                the modified Terms, then you may not use the he Device, the Site, or the
                                                App anymore. Heliponix reserves the right to change or discontinue all
                                                or part of the Services at any time and without notice.</p>
                                        </li>
                                        <li>
                                            <p>Use of Device<br/>
                                                The Device is intended for use as a aeroponic plant-growing machine.
                                                G-Pods for use in the Device may only be purchased directly from
                                                Heliponix, through the Site or the App. The Device is intended to grow
                                                only fruits, vegetables, and herbs only from pods from Heliponix. The
                                                Device may not be used for growing marijuana or other illegal plants or
                                                materials. Use of the Device to grow pods from other sources, marijuana,
                                                or other illegal plants or materials voids the Return Policy and all
                                                Warranties contained in these Terms.</p>
                                        </li>
                                        <li>
                                            <p>Device Monitoring<br/>
                                                Devices include a camera that is used to monitor the Devices and plants
                                                grown inside. Heliponix may access the camera at any time, to monitor
                                                your Device live or take images of your Device and plants. If you do not
                                                wish to share images of your Device and your plants with Heliponix, you
                                                may opt out and turn the camera off through the App. If you opt out,
                                                Heliponix will not monitor your Device live or take any images of your
                                                Device or plants. Disabling the camera will inhibit our ability to
                                                troubleshoot your device.</p>
                                        </li>
                                        <li>
                                            <p>Cleaning the Device<br/>
                                                The Device must be cleaned properly and frequently, according to
                                                instructions provided by Heliponix on the Site and the App, to maintain
                                                the health of the Device and of food grown in the Device. By using the
                                                Device, you are agreeing to clean the device when and as directed by
                                                Heliponix. </p>
                                        </li>
                                        <li>
                                            <p>G-Pods and Food Safety <br/>
                                                G-Pods for use in the Device (&ldquo;Pods&rdquo;) may only be
                                                purchased directly from Heliponix, through the Site or the App. Pods
                                                from other sources may not be used in the Device. While the seeds are
                                                tested for quality and safety, food grown in the Device may be subject
                                                to damage, rotting, insects, or other naturally occurring conditions.
                                                Improper or infrequent cleaning of the Device against the provided
                                                Heliponix instructions may result in bacteria growth inside the Device.
                                                Heliponix is not liable for any food or illness or other damages
                                                resulting from using the Device. </p>
                                        </li>
                                        <li>
                                            <p>Personal Use Only<br/>
                                                Heliponix&rsquo;s Device, Pods, and other products and services are for
                                                your personal, non-commercial use only. Once delivered to you, Devices,
                                                Pods, and other products and services may not be resold, redistributed,
                                                exported, or used for any other commercial purpose. The rights you have
                                                under these Terms are personal to you and are non-transferable.
                                                Heliponix does not offer returns, exchanges, or customer service for any
                                                Paid Services that have been acquired from an unauthorized reseller,
                                                including, but not limited to, any seller on Amazon, eBay or similar
                                                online marketplaces.</p>
                                        </li>
                                        <li>
                                            <p>Return Policy<br/>
                                                If you are not satisfied with the Device, it may be returned to
                                                Heliponix within 100 days of purchase for a full refund if the Device
                                                was only used as instructed by Heliponix. Use of Device to grow other
                                                source pods, marijuana, or any illegal plants or materials voids your
                                                ability to return the Device to Heliponix, and no refund will be issued
                                                for such misused Devices. <br/>
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p className="Legal-title">Website and User Account</p>

                                    <ol>
                                        <li>
                                            <p>User Data Collected<br/>
                                                Heliponix will collect user data via the Site and the App regarding use
                                                of the Device. This data will be used to improve and enhance user
                                                experience with the Device and the Site and App. By using the Device,
                                                Site and/or App, you agree to allow Heliponix to collect data on your
                                                use of the Device. If you do not agree to allow Heliponix to collect
                                                data, do not use the Device, Site, or App.</p>
                                        </li>
                                        <li>
                                            <p>User Data Privacy<br/>
                                                Please see our Privacy Policy for information about your user data
                                                privacy.</p>
                                        </li>
                                        <li>
                                            <p>User Account and Password Policy<br/>
                                                If you want to use certain features of the Device, you must create an
                                                account (&ldquo;Account&rdquo;) with Heliponix. You can create an
                                                Account through the Site or the App. You must provide Heliponix with
                                                accurate, complete, and up-to-date information for your Account, and you
                                                agree to update such information as needed, to keep it accurate,
                                                complete, and up-to-date. If you fail to keep your Account updated,
                                                Heliponix reserves the right to suspend or terminate your Account at any
                                                time and without notice. You shall not disclose your Account password to
                                                anyone, and You shall notify Heliponix immediately of any unauthorized
                                                use of your Account. You are responsible for all activities that occur
                                                under your Account, whether or not you know about them. <br/>
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p className="Legal-title">Pod Ordering</p>

                                    <ol>
                                        <li>
                                            <p>Purchasing and Pricing<br/>
                                                Pods are available for sale only directly from Heliponix. Pods may be
                                                purchased individually or on an ongoing subscription basis. Pricing may
                                                vary, depending on the frequency and quantity of order. Prices are
                                                subject to change at any time, with or without notice.</p>
                                        </li>
                                        <li>
                                            <p>Payment<br/>
                                                The purchase of any Device, Pods, or other products (the &ldquo;Paid
                                                Services&rdquo;) may be subject to payments now or in the future, which
                                                in certain cases may include recurring, automatically renewing payment
                                                obligations (as further described in D. Subscription Services for Pods
                                                section below). You may place an order for Paid Services at any time
                                                through the Site or the App. You may check and correct any input errors
                                                in your order up until the point at which you submit your order to
                                                Heliponix by clicking the &ldquo;Submit Purchase&rdquo; button on the
                                                order page. An order submitted by you constitutes a legally binding
                                                offer given by you to Heliponix to purchase the Paid Services specified
                                                in that order at the price and on the terms stated when you place your
                                                order. All orders are subject to acceptance by Heliponix. Your order
                                                will not be considered accepted until Heliponix has received payment of
                                                the purchase price of your order. Heliponix may send an acknowledgment
                                                of our receipt of your order to the email address you provide, after
                                                your payment for the order has been processed, so that you may print the
                                                information for your records. Unless otherwise stated at the time you
                                                place your order, title and risk of loss pass to you upon delivery to
                                                the shipping address you specified when you placed the order, provided
                                                full payment of all sums due in respect of the product(s), including any
                                                delivery charges, has been received.</p>
                                        </li>
                                        <li>
                                            <p>Billing<br/>
                                                Heliponix a third-party payment processor (&ldquo;Payment
                                                Processor&rdquo;) to bill you through a payment account linked to your
                                                Account on the Site or the App for the Paid Services. The processing of
                                                payments will be subject to the terms, conditions and privacy policies
                                                of the Payment Processor, in addition to these Terms. More information
                                                is available in our Privacy Policy. Heliponix is not responsible for
                                                errors by the Payment Processor. By choosing to use Paid Services, you
                                                agree to pay Heliponix, through the Payment Processor, all charges at
                                                the prices then in effect for any use of such Paid Services in
                                                accordance with the applicable payment terms and you authorize
                                                Heliponix, through the Payment Processor, to charge your chosen payment
                                                provider (your &ldquo;Payment Method&rdquo;). You agree to make payments
                                                using that selected Payment Method. Heliponix reserves the right to
                                                correct any errors or mistakes that the Payment Processor, makes even if
                                                it has already requested or received payment.</p>
                                        </li>
                                        <li>
                                            <p>Subscription Services for Pods<br/>
                                                Some of the Paid Services, such as the purchase of auto-replacing Pods,
                                                may consist of recurring periodic charges as agreed to by you when you
                                                sign up for such auto-replacing products (&ldquo;Subscription
                                                Services&rdquo;). The Subscription Services give you the ability to
                                                specify how regularly you would like to order any auto-replacing
                                                products. Unless you opt out of a Subscription Service, as described in
                                            </p>
                                        </li>
                                        <li>
                                            <p>Cancellation Policy, the Subscription Services that you have signed up
                                                for will be automatically extended for successive renewal periods. By
                                                choosing a Subscription Service, you acknowledge that such Subscription
                                                Service has an initial and recurring payment feature, and you accept
                                                responsibility for all recurring charges prior to the effective date of
                                                cancellation of such Subscription Service. To change or cancel your
                                                Subscription Services at any time, go to your Account via the Site or
                                                the App. If you terminate a Subscription Service, your subscription will
                                                not be renewed after your then-current subscription term expires.
                                                Heliponix may submit periodic charges without further authorization from
                                                you, unless you cancel your Subscription Service. To terminate your
                                                authorization or change your payment method or Subscription Services, go
                                                to your Account. </p>
                                        </li>
                                        <li>
                                            <p>Cancellation Policy<br/>
                                                To cancel your Subscription Services at any time, go to your Account via
                                                the Site or the App. If you terminate a Subscription Service, your
                                                subscription will not be renewed after your then-current subscription
                                                term expires. By choosing a Subscription Service, you accept
                                                responsibility for all recurring charges prior to the effective date of
                                                cancellation of such Subscription Service. </p>
                                        </li>
                                        <li>
                                            <p>Pod Availability<br/>
                                                Pod availability may vary, based on seasonality, demand, or other
                                                factors. Heliponix may substitute similar Pods in your order or
                                                Subscription Services if the Pod(s) you selected are unavailable.
                                                Heliponix will provide notice to you through your Account of any
                                                substitutions</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p className="Legal-title">Prohibited Uses</p>

                                    <ol>
                                        <li>
                                            <p>Use of Other Pods<br/>
                                                Pods may only be purchased directly from Heliponix, through the Site or
                                                the App. Pods from other sources, including other sellers, resellers,
                                                manufacturers, and retailers, may not be used in the Device. The use of
                                                any prohibited pods in your Device voids the Return Policy and all
                                                Warranties contained in these Terms. </p>
                                        </li>
                                        <li>
                                            <p>Illegal Plants and Drugs<br/>
                                                The Device may not be used for growing marijuana or other illegal plants
                                                or materials. Regardless of the laws of the state where you use the
                                                Device, use of the Device to grow marijuana, or other illegal plants or
                                                materials voids the Return Policy and all Warranties contained in these
                                                Terms.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p className="Legal-title">Intellectual Property</p>
                                </li>
                            </ol>

                            <p>The Device and Pods are covered by international mechanical utility PCT patents, and the
                                company name and logo are protected by trademarks. You represent that all Heliponix
                                content and all intellectual property arising out of or related to the Device, Site, and
                                App is owned by Heliponix and is subject to copyright and other intellectual property
                                law, including specifically the Heliponix name, Device functionality, trademarks, and
                                all related names, logos, product and service names, designs and slogans
                                (&ldquo;Heliponix IP&rdquo;) are the intellectual property of Heliponix. You shall not
                                copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise
                                exploit any Heliponix content or the Heliponix IP without the prior written consent of
                                Heliponix. Heliponix shall not be liable for the accuracy, usefulness, safety, or the
                                Heliponix IP or the intellectual property rights of or relating to Heliponix
                                content. </p>

                            <ol className="Legal-big-list" start="6">
                                <li>
                                    <p className="Legal-title">Disclaimers</p>

                                    <ol className="Legal-big-list">
                                        <li>
                                            <p>Manufacturer Warranties<br/>
                                                90 day warranty </p>
                                        </li>
                                        <li>
                                            <p>Prohibited Uses Void Warranty<br/>
                                                The prohibited uses listed in IV. Prohibited Uses void any and all
                                                Warranties from Heliponix contained in these Terms.</p>
                                        </li>
                                        <li>
                                            <p>Limitation of Liability and Warranties<br/>
                                                Heliponix makes no warranties of any kind whatsoever with regard to any
                                                Devices, Pods, products, or services sold on or available through the
                                                Site and App and specifically disclaims any and all such warranties,
                                                including without limitation, the warranties of merchantability and
                                                warranties of fitness for a specific purpose. Any use of the Device,
                                                Site, and/or App is at your sole risk. To the fullest extent permitted
                                                by law, Heliponix, its officers, directors, employees, and agents
                                                disclaim all warranties, express or implied, in connection with your Use
                                                and the Device, Site, and App. Heliponix makes no warranties or
                                                representations with respect to the accuracy or completeness of the
                                                Site, App, or any content or the content of any websites linked to the
                                                Site or App and assumes no liability for any claims of any kind arising
                                                out of or related to your Use and access of the Device, Site, and App.
                                                Such disclaimer of liability includes but is not limited to liability
                                                arising out of or related to unauthorized access of your content or your
                                                account, interruption, or cessation of service, security breaches
                                                through virus or other means. You agree to indemnify Heliponix against
                                                and to release Heliponix from and hold Heliponix harmless from any and
                                                all claims or liability arising out of or related to the Device, Site,
                                                and App and to defend and indemnify Heliponix, its officers, directors,
                                                employees, and agents, from and against any and all claims, damages,
                                                obligations, losses, liabilities, costs, or debt, including attorney
                                                fees, arising out of or related to your use in any manner.<br/>
                                                Heliponix shall maintain all purchase, sale, and payment information in
                                                a confidential manner as required by law, and shall notify you in the
                                                event of any breach, compromise, or accidental disclosure of any such
                                                information, including credit card information. By your use or purchase
                                                through the Site, App, or Services, you agree to hold Heliponix harmless
                                                from and indemnify Heliponix against any claim or liability arising out
                                                of or related to confidentiality of such payment information. </p>
                                        </li>
                                        <li>
                                            <p><strong>Dispute Resolution</strong><br/>
                                                These Terms and any action related thereto will be governed by the laws
                                                of the State of Indiana without regard to its conflict of laws
                                                provisions. You agree to resolve any claim, cause of action, or dispute
                                                you have with Heliponix arising out of or relating to these Terms, your
                                                use of the Device, Site, and/or App, and/or the Privacy Policy
                                                exclusively in a state court located in Indiana, and you agree to submit
                                                to the personal jurisdiction of such courts for the purpose of
                                                litigating all such claims. </p>

                                            <ol>
                                                <li>
                                                    <p>Arbitration<br/>
                                                        You agree that all disputes between you and Heliponix,
                                                        regardless if such dispute involves a third party, including
                                                        without limitation disputes related to these Terms, your use of
                                                        the Device, Site, and/or App, and/or the Privacy Policy, will be
                                                        resolved by binding, individual arbitration under the American
                                                        Arbitration Association&rsquo;s (&ldquo;AAA&rdquo;) Rules for
                                                        Arbitration of Consumer-Related Disputes. You expressly waive
                                                        trial by jury. If you violate or threaten Heliponix&rsquo;s
                                                        intellectual property rights, Heliponix may seek injunctive or
                                                        other relief in any state or federal court in the State of
                                                        Indiana. You may bring claims only on your own behalf. Neither
                                                        you nor Heliponix will participate in a class action or
                                                        class-wide arbitration for any claims covered by this agreement
                                                        to arbitrate. This dispute resolution provision will be governed
                                                        by the Federal Arbitration Act and not by any state law
                                                        concerning arbitration. </p>
                                                </li>
                                                <li>
                                                    <p>Severability<br/>
                                                        If the prohibition against class actions and other claims
                                                        brought on behalf of third parties contained above is found to
                                                        be unenforceable, then all of the preceding language in this D.
                                                        Dispute Resolution section will be null and void. This
                                                        arbitration agreement will survive the termination of your
                                                        relationship with us.</p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ProductTerms;
