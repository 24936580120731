import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InputGroup, Input, InputGroupAddon, Button} from "reactstrap";

@inject('seedStore')
@observer
class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchIsActive: false,
            query: "",
        }
    }

    onFocus() {
        this.setState({searchIsActive: true});
    }

    onBlur() {
        this.setState({searchIsActive: false});
    }

    onChange(input) {
        this.setState({query: input.target.value});
        this.props.seedStore.searchSeeds(input.target.value);
    }

    onCancel() {
        this.setState({query: ""});
        this.props.seedStore.searchSeeds("");
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.seedStore.searchSeeds(this.state.query);
    }

    render() {
        return (
            <div className={this.state.searchIsActive ? "custom-search-box active" : "custom-search-box"}>
                <form className="form-inline" onSubmit={this.onSubmit.bind(this)}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button color="primary" type="submit" className="text-white btn-sm z-index-0 no-focus">
                                <FontAwesomeIcon icon={faSearch}/>
                            </Button>
                        </InputGroupAddon>
                        <Input
                            style={{height: 38}}
                            type="search"
                            name="search"
                            id="exampleSearch"
                            placeholder="Search Gropods"
                            className="form-control no-focus"
                            value={this.state.query}
                            onChange={this.onChange.bind(this)}
                            onFocus={this.onFocus.bind(this)}
                            onBlur={this.onBlur.bind(this)}
                        />
                    </InputGroup>
                    <Button 
                        color="link" className={"text-secondary " + (this.state.query.length? "d-block": "d-none")}
                        onClick={this.onCancel.bind(this)}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </Button>
                </form>
            </div>
        );
    }
}

export default Search;
