import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { ButtonGrey, ButtonGreen, ButtonWhite } from "../../../components/Buttons";
import { inject, observer } from "mobx-react";
import EmptyMessage from "../../../components/EmptyMessage";
import { formatDate } from "../../../utils/UtilityFunctions";

@inject('orderStore', 'cartStore')
@withRouter
@observer
class OrderHistory extends Component {
    componentWillMount() {
        this.props.orderStore.getOrders();
    }

    renderSubscriptionItems() {
        if (this.props.orderStore.myOrders.length) {
            return this.props.orderStore.myOrders.map((order, i) => {
                return (
                    <div key={order.id} className="Order-item-div">
                        <div className="Order-top-row">
                            <p className="Text-cat-title-2">{`Date: ${formatDate(order.orderdate)}`}</p>
                            <p className="Text-cat-title-2">{`Order Total: $${order.total.toFixed(2)}`}</p>
                        </div>
                        {order.products.map((product, j) => {
                            return (
                                <div key={product.id}>
                                    <p className="mt-2">{`Order Number: ${order.id}`}</p>
                                    <div key={`product div ${i}${j}`} className="Order-row">
                                        <div className="Order-info-row">
                                            <img src={product.image} alt={product.name} className="Order-product-image" />
                                            <div className="Order-name-div">
                                                <p className="Order-name Text-cat-title-2-green text-capitalize">{product.name}</p>
                                                <p className="">{`Qty: ${product.qty}`}</p>
                                                {
                                                    product.size &&
                                                    <p className="">{`Size: ${product.size ? product.size : ''}`}</p>
                                                }

                                            </div>
                                        </div>
                                        <div className="Order-controls">
                                            <ButtonGreen
                                                className="Order-button-buy"
                                                textClass="Round-button-text-small"
                                                label="Buy Again"
                                                onClick={() => this.props.cartStore.addItem(product.id)}
                                            />
                                            <ButtonGrey
                                                className="Order-button-pdf"
                                                textClass="Round-button-text-grey-small"
                                                label="Details"
                                                onClick={() => this.props.history.push(`/myaccount/orderhistory/details`, { id: order.id })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })
        } else {
            return (
                <EmptyMessage message="You have no order history.">
                    <ButtonWhite
                        className="mx-auto mt-4"
                        label="Shop Products"
                        onClick={() => this.props.history.push('/gropods')}
                    />
                </EmptyMessage>
            )
        }
    };

    render() {
        return (
            <div className="Order-contents">
                {this.renderSubscriptionItems()}
            </div>
        );
    }
}

export default OrderHistory;
