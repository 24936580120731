import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import { Route } from 'react-router-dom';
import RoundButton from "../../../components/RoundButton";
const tempBadge = require("../../../assets/dashStats/tempBadge.png");
const waterBadge = require("../../../assets/dashStats/waterBadge.png");
const lightBadge = require("../../../assets/dashStats/lightBadge.png");
const cloudBadge = require("../../../assets/dashStats/cloudBadge.png");

@inject('userStore')
@observer
class DashView extends Component {
    
    componentWillMount() {
        //this.props.userStore.getGropod(this.props.gropod);
    }

    handleGropodSnapshot = (e, history) => {
        e.preventDefault()
        history.push('../myaccount/liveview')
    }

    renderGropodStat = (value, title, image) => {
        return (
            <div className="Dashboard-stat-div">
                <img src={image} alt="GroPod Statistics" className="Dashboard-stat-image" />
                <div className="Dashboad-stat-right-div">
                    <h2 className="Text-title-large-green">{value}</h2>
                    <p className="Text-dash-stat">{title}</p>
                </div>
            </div>
        )
    };

    Button = () => (
        <Route render={({ history}) => (
            <RoundButton 
                className="Dashboard-gropod-snapshot-btn" 
                label="GroPod Snapshot" 
                onClick={(e)=>{
                    this.handleGropodSnapshot(e, history)
                }}
            />
        )} />
    );

    render() {
        
        if(this.props.userStore.isLoading) {
            return <div className="alert alert-light">Loading</div>;
        }
        let waterlevel = "";
        if(this.props.userStore.gropod.waterlevel >= 20)
            waterlevel = "Contact Support";
        else if(this.props.userStore.gropod.waterlevel >= 13)
            waterlevel = "Empty";
        else if(this.props.userStore.gropod.waterlevel >= 10)
            waterlevel = "Low";
        else if(this.props.userStore.gropod.waterlevel >= 5)
            waterlevel = "Good";
        else
            waterlevel = "High";
        return (
            <div className="Dashboard-content" >
                <div className="Dashboard-stat-row">
                    {this.renderGropodStat(Math.round(this.props.userStore.gropod.temperature)+"F", "Temperature", tempBadge )}
                    {this.renderGropodStat(waterlevel, "Water Level", waterBadge )}
                </div>
                <div className="Dashboard-stat-row">
                    {this.renderGropodStat(Math.round(this.props.userStore.gropod.humidity)+"%", "Humidity", cloudBadge )}
                    {this.renderGropodStat("Good", "Light Status", lightBadge )}
                </div>
                {this.Button()}
            </div>
        )
    }
}

export default DashView;
