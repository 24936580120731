import { observable, action, computed } from 'mobx';
import appFetch from '../utils/AppFetch'
import { stopPropagation } from '../utils/UtilityFunctions'
import routes from '../utils/ApiRoutes'
import alertStore from './AlertStore'

class CartStore {
    @observable isLoading = false;
    @observable activeTab = 1;
    @observable cart = {};
    @observable cartIsEmpty = {};

    @action setActiveTab(tab) {
        this.activeTab = tab;
    }

    @action clearCart() {
        this.cart = {};
    }

    @computed get numItemsInCart() {
        let total = null;
        if (this.cart.products) {
            total = 0;
            for (let product of this.cart.products) {
                total += product.qty
            }
        }
        return total;
    }

    @action
    async getCart() {
        this.isLoading = true;
        const api = routes.cart.getCart();
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.cart = returnData;
            this.isLoading = false;
        } else {
            this.cart = {};
            this.isLoading = false;
        }
    }

    @action async addItem(event, id, qty, size) {
        stopPropagation(event);
        this.isLoading = true;

        const formData = { id: id, qty: qty ? qty : 1, size: size ? size : 'XL' };

        const api = routes.cart.addItem();
        const returnData = await appFetch(api.url, formData, api.method);

        if (returnData.httpStatus === 200) {
            this.cart = returnData;
            this.isLoading = false;
            alertStore.setMessage({ type: "success", text: "Item successfully added to your cart" })
        } else {
            this.cart.products.push({ id: id, qty: 1, size: size ? size : 'XL' });
            alertStore.setMessage({ type: "danger", text: "Error: Item could not be added to your cart" })
        }
    }

    @action async updateQty(id, qty) {
        this.isLoading = true;
        const formData = { id: id, qty: qty };
        const api = routes.cart.updateQty();
        const returnData = await appFetch(api.url, formData, api.method);

        if (returnData.httpStatus === 200) {
            this.cart = returnData;
            this.isLoading = false;
            alertStore.setMessage({ type: "success", text: "Quantity successfully updated" })
        } else {
            for (let product of this.cart.products) {
                if (product.id === id)
                    product.qty = qty
            }
            alertStore.setMessage({ type: "danger", text: "Error: Quantity could not be updated" })
        }
    }
}

export default new CartStore();
