import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

import {Elements, StripeProvider} from 'react-stripe-elements';
import { Provider } from 'mobx-react';
import appStore from './stores/AppStore';
import alertStore from './stores/AlertStore';
import cartStore from './stores/CartStore';
import userStore from './stores/UserStore';
import dropdownStore from './stores/DropdownStore';
import productStore from './stores/ProductStore';
import formStore from './stores/FormStore';
import seedStore from './stores/SeedStore';
import orderStore from './stores/OrderStore';
import subscriptionStore from './stores/SubscriptionStore';

const stores = {
    appStore,
    alertStore,
    cartStore,
    userStore,
    dropdownStore,
    formStore,
    productStore,
    seedStore,
    orderStore,
    subscriptionStore,
};

if (document.getElementById('root')) {
    ReactDOM.render((
        <Provider {...stores}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Elements>
                    <App />
                </Elements>
            </StripeProvider>
        </Provider>
    ), document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
