import {observable, action} from 'mobx';
import appFetch from '../utils/AppFetch'
import routes from '../utils/ApiRoutes'

class SubscriptionStore {
    @observable isLoading = false;
    @observable shipments = {};
    @observable mySubscription = {};
    @observable newSubscription = {};
    @observable skipNext = false;

    @action async getShipments() {
        this.isLoading = true;
        const api = routes.subscription.getShipments();
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.shipments = returnData;
            this.isLoading = false;
        }
    }

    @action async getMySubscription() {
        this.isLoading = true;
        const api = routes.subscription.getMySubscription();
        const returnData = await appFetch(api.url);

        if (returnData.httpStatus === 200) {
            this.mySubscription = returnData;
            this.isLoading = false;
        } else if(returnData.httpStatus === 404) {
            this.mySubscription = {};
            this.isLoading = false;
        }
        return returnData;
    }

    @action async skipSubscription() {
        const api = routes.subscription.skipSubscription();
        const returnData = await appFetch(api.url, {}, api.method);
        this.skipNext = true;

        if (returnData.httpStatus === 200) {
            this.getMySubscription();
            this.isLoading = false;
        } else if(returnData.httpStatus === 404) {
            this.isLoading = false;
        }
    }
}

export default new SubscriptionStore();
