import React, { Component } from "react";

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <button
                className={ [ "Round-button", this.props.className ].join(' ')}
                onClick={ this.props.onClick }
                type={this.props.type}
                style={this.props.style}
            >
                <div className="Round-button-content-div" >
                    <p className={ [ "Round-button-text", this.props.textClass ].join(' ')}>{this.props.label}</p>
                </div>
            </button>
        );
    };
}
