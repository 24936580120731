import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import StarRating from "../../components/StarRating";
import {ButtonSquareWhite} from "../../components/Buttons";

@inject('productStore')
@withRouter
@observer
class RelatedProducts extends Component {
    
    renderRelatedProducts() {
        return this.props.products.map((product, i) => {
            return (!this.props.related || this.props.related.includes(product.id)) ?
                <div key={i} className="Relatedproducts-package-div">
                    <div className="Relatedproducts-package-upper-div">
                        <img src={product.thumbnail} alt="Product" className="Relatedproducts-img"/>
                    </div>
                    <div className="Relatedproducts-package-lower-div">
                        <p className="Text-cat-title-2-green">{`${product.name}`}</p>
                        <p className="Text-standard-font-small text-box-truncate">{`${product.shortdescription}`}</p>
                        <div className="Relatedproducts-package-row">
                            <div className="Relatedproducts-star-div">
                                <StarRating rating = {product.rating}/>
                            </div>
                            <p className="Text-robo-med">{product.price}</p>
                        </div>
                        <ButtonSquareWhite
                            height={52}
                            className="Products-squarebutton w-100"
                            label="View Details"
                            textClassName="Text-robo-bold-18-green"
                            onClick={()=>this.props.history.push(`/products/${product.permalink}`, { id: product.id})}
                        />
                    </div>
                </div>
            : null
        })
    };

    handleScroll = () => {
        this.relatedScroll.scrollTo({left: this.relatedScroll.scrollLeft + 300, top: 0, behavior: 'smooth' });
    };
    
    render() {
        return (
            null
        );
    }
}

export default RelatedProducts;
