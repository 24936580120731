import React, { Component } from 'react';
import { ButtonGrey } from "../../components/Buttons";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import QuantityFilled from "./QuantityFilled";
import TooMany from "../../components/modals/TooManySeeds";
import TooFew from "../../components/modals/TooFewSeeds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { injectStripe } from "react-stripe-elements";
import CartItemsRemoveModal from "../../components/modals/CartItemsRemoveModal";

@inject('subscriptionStore', 'seedStore', 'appStore', 'userStore', 'formStore')
@withRouter
@observer
class SubscriptionCart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
        this.removeAllItems = this.removeAllItems.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    updateCart(name) {
        this.props.seedStore.updateSeeds(name)
    }

    removeAllItems() {
        this.props.seedStore.updateSeeds()
        this.toggle()
    }

    async onSubmit(e) {
        e.preventDefault();

        if (this.props.userStore.authUser.subscription) {
            this.props.formStore.onUpdateSubscription(this.props.history)
        } else if (this.props.formStore.useSavedToken) {
            this.props.formStore.onSubmitSubscription(this.props.history);
        } else {
            const token = await this.saveToken();
            if (token) {
                this.props.formStore.onSubmitSubscription(this.props.history);
            }
        }
    }

    async saveToken() {
        const { token } = await this.props.stripe.createToken({
            name: this.props.formStore.form.nameOnCard,
            // eslint-disable-next-line
            address_zip: this.props.formStore.form.paymentZip
        });

        if (token) {
            this.props.formStore.setPaymentToken(token);
        }

        return token;
    }

    renderPackageIncludes(pack, qty) {
        return pack.included.map((item, index) => {
            return (
                <div key={index} className="Seedpods-cart-row ml-4">
                    <p>{item.name}</p>
                    <p>{item.qty * qty}</p>
                </div>
            )
        })
    }

    renderPackages() {
        const cart = [];
        for (const key of Object.keys(this.props.seedStore.myPkg)) {
            if (this.props.seedStore.myPkg[key] && this.props.seedStore.myPkg[key].qty && this.props.seedStore.myPkg[key].qty.value !== "0") {
                cart.push(
                    <div>
                        <div className="Seedpods-cart-row">
                            <p>{this.props.seedStore.myPkg[key].name} ({this.props.seedStore.myPkg[key].qty.value})</p>
                        </div>
                        {this.renderPackageIncludes(this.props.seedStore.myPkg[key], this.props.seedStore.myPkg[key].qty.value)}
                    </div>
                )
            }
        }
        if (cart.length) {
            return (
                <div className="mb-3">
                    <p className="font-weight-bold font-size-sm">Pre-set Seed Packages</p>
                    {cart.map((row, index) => (<div key={index}>{row}</div>))}
                </div>
            )
        }
    }

    renderSeeds() {
        const cart = [];

        for (const key of Object.keys(this.props.seedStore.myCustomSeeds)) {
            if (this.props.seedStore.myCustomSeeds[key] && this.props.seedStore.myCustomSeeds[key].value !== "0") {

                cart.push(
                    <div>
                        <div className="Seedpods-cart-row">
                            <p>{key}</p>
                            <p>{this.props.seedStore.myCustomSeeds[key].value}
                                <FontAwesomeIcon icon={faTrash}
                                    onClick={() => {
                                        this.updateCart(key)
                                    }}
                                    className="trash-cat-seedpods" />
                            </p>
                        </div>
                    </div>
                )
            }
        }
        if (cart.length) {
            return (
                <div>
                    <p className="font-weight-bold">Custom Gropods</p>
                    {cart.map((row, index) => (<div key={index}>{row}</div>))}
                </div>
            )
        }
    }

    onClickNext() {
        this.props.appStore.goToCheckout(this.props.history);
    }

    render() {
        return (
            <div className="Seedpods-cart-widget">
                <div>
                    {
                        this.state.modal &&
                        <CartItemsRemoveModal
                            toggle={this.toggle}
                            removeAllItems={this.removeAllItems}
                            modal={this.state.modal}
                        />
                    }
                </div>
                <div className="SeedPods-cart-title-row">
                    <p className="Text-cat-title">Cart</p>
                    <FontAwesomeIcon icon={faTrash}
                        onClick={() => {
                            this.toggle()
                        }}
                        className="trash-cat-all" />

                    <p className="Text-cat-title">Qty</p>

                </div>
                <div className="Seedpods-cart-content">
                    {this.renderPackages()}
                    {this.renderSeeds()}
                    {this.props.seedStore.filledSeeds === 0 && <div className="text-secondary">No seeds added</div>}
                </div>
                <div className="SeedPods-cart-bottom-row">
                    <QuantityFilled />
                    {this.props.seedStore.activeTab === 3 ?
                        <ButtonGrey
                            className="Seedpods-cart-next-button mb-2"
                            label={this.props.userStore.authUser.subscription ? "Update" : "Place Order"}
                            onClick={(e) => this.onSubmit(e)}
                        /> :
                        <ButtonGrey
                            onClick={() => this.onClickNext()}
                            className="Seedpods-cart-next-button mb-2"
                            label={"Next"}
                        />
                    }
                </div>
                <TooMany />
                <TooFew />
            </div>
        );
    }
}

export default injectStripe(SubscriptionCart);
