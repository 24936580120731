import React, {Component} from 'react';
import PageTitle from '../../components/PageTitle';

export default class AccountTitle extends Component {
    render() {
        return (
            <PageTitle
                title={titles[this.props.type].title}
                subtitle={titles[this.props.type].subtitle}
            />
        );
    }
}

const titles = {
    dashboard: {
        title: "Dashboard",
        subtitle: "",
    },
    myAccount: {
        title: "My Account",
        subtitle: "",
    },
    subscription: {
        title: "Subscription",
        subtitle: "",
    },
    orderHistory: {
        title: "Order History",
        subtitle: "",
    },
    help: {
        title: "Help",
        subtitle: "",
    },
    liveView: {
        title: "Pure Produce&trade; Live View",
        subtitle: "Tuning in to your GroPod™",
    },
    logout: {
        title: "Logout",
        subtitle: "",
    },
};
