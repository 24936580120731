import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter, NavLink} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
const accountBadge = require("../../assets/accountBadge.png");

@inject('userStore', 'appStore', 'formStore')
@withRouter
@observer
class AccountRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gropodId: "8P7U5TH",
            showMenu: false,
        };
    }
    
    toggleMenu() {
        this.setState({showMenu: !this.state.showMenu})
    }
    
    renderMenu(authUser) {
        if(this.props.appStore.width > 850 || this.state.showMenu) {
            return (
                <div className= "Account-tabs-container">
                    <p className="Account-gropodId-text my-2">
                        <b>GroPod Id:</b>{this.props.userStore.gropod.serial}
                    </p>
                    <NavLink 
                        exact={true} activeClassName="Account-active-tab" className="Account-link" to='/myaccount'
                        onClick={this.toggleMenu.bind(this)}>
                        <div className="Account-tab"><p className="Text-account-link">DASHBOARD</p></div>
                    </NavLink>
                    <NavLink 
                        exact={true} activeClassName="Account-active-tab" className="Account-link" to='/myaccount/account'
                        onClick={this.toggleMenu.bind(this)}>
                        <div className="Account-tab"><p className="Text-account-link">ACCOUNT</p></div>
                    </NavLink>
                    <NavLink 
                        exact={true} activeClassName="Account-active-tab" className="Account-link" to='/myaccount/subscription'
                        onClick={this.toggleMenu.bind(this)}>
                        <div className="Account-tab"><p className="Text-account-link">SUBSCRIPTION</p></div>
                    </NavLink>
                    <NavLink 
                        exact={true} activeClassName="Account-active-tab" className="Account-link" to='/myaccount/orderhistory'
                        onClick={this.toggleMenu.bind(this)}>
                        <div className="Account-tab"><p className="Text-account-link">ORDER HISTORY</p></div>
                    </NavLink>
                    <NavLink 
                        exact={true} activeClassName="Account-active-tab" className="Account-link" to='/myaccount/help'
                        onClick={this.toggleMenu.bind(this)}>
                        <div className="Account-tab"><p className="Text-account-link">HELP</p></div>
                    </NavLink>
                    <NavLink exact={true} className="Account-link" to='#'>
                        <div className="Account-tab" onClick={()=>this.props.formStore.onLogout(this.props.history)}>
                            <p className="Text-account-link">LOGOUT</p>
                        </div>
                    </NavLink>
                </div>
            )
        }
    }
    
    render() {
        const {authUser} = this.props.userStore;
        return (
            <div className="Account-right-div">
                <div className="Account-nav-outer-div">
                    <div className="Account-nav Account-margin-right">
                        <div className="Account-nav-header">
                            <img src={accountBadge} alt="" className="Account-badge-image" />
                            <p className="Text-small-title Account-username text-capitalize">{authUser.firstName} {authUser.lastName}</p>
                            {(this.props.appStore.width <= 850 && !this.state.showMenu) && 
                            <FontAwesomeIcon icon={faPlus} className="Account-plus-button" onClick={this.toggleMenu.bind(this)}  />}
                            {(this.props.appStore.width <= 850 && this.state.showMenu) && 
                            <FontAwesomeIcon icon={faMinus} className="Account-plus-button" onClick={this.toggleMenu.bind(this)} />}
                        </div>
                        {this.renderMenu(authUser)}
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountRight;
