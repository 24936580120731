import React, { Component } from 'react';
import Dropdown from "../../../../components/Dropdown.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import SubscriptionPackage from "../../SubscriptionPackage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {inject, observer} from "mobx-react";
import DropdownSelect from "../../../../components/forms/DropdownSelect";

@inject('seedStore')
@observer
class PreSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPackage: {
                qty: 10,
                price: 9,
                description: "Package Description",
                savings: 30,
            },
        };
    }
    
    componentWillMount() {
        this.props.seedStore.getPackages();
    }

    renderPackage = () => {
        return (
            <div className="Select-package-div">
                <div className="ChooseAmount-package-upper-div">
                    <div className="ChooseAmount-circle-div">
                        <p className="Text-large-white">{`${this.state.selectedPackage.qty}`}</p>
                        <p className="Text-white-robo-reg">PACK</p>
                    </div>
                    <p className="Text-robo-med">{`${this.state.selectedPackage.qty} G-Pod Pack`}</p>
                </div>
                <div className="ChooseAmount-package-lower-div" style={{paddingTop:24}}>
                    <p className="ChooseAmount-description-text">{`${this.state.selectedPackage.description}`}</p>

                    <p className="Text-black-robo Select-price-text">{`$${this.state.selectedPackage.price}`}</p>
                    {this.state.selectedPackage.savings && <p className="Text-robo-reg-blue">{`${this.state.selectedPackage.savings}% Savings`}</p>}
                    <Dropdown width={"100%"} optionArray={[10, 20, 30, 40, 50, 60]} placeHolder="Select Package" className="Select-Package-dropdown" selectedItem={this.state.selectedItem} selectItem={((item) => this.setState({selectedState: item}))}/>
                    <Dropdown width={"100%"} optionArray={[10, 20, 30, 40, 50, 60]} placeHolder="Select Frequency" className="Select-Package-dropdown" selectedItem={this.state.selectedItem} selectItem={((item) => this.setState({selectedState: item}))}/>
                </div>
            </div>
        )
    };

    renderPackages() {
        return this.props.seedStore.packages.map((pack, i) => {
            const myPkg = this.props.seedStore.myPkg;
            return(
                <div key={i} className="Preselect-package-div">
                    <div className="Preselect-package-row">
                        <div className="Preselect-package-div-left">
                            <img className="Preselect-img" alt="Seedpod Pack" src={pack.img} />
                            <div className="Preselect-harvest-row">
                                <FontAwesomeIcon icon={faClock} className="Preselect-clock-icon"  />
                                <p className="Text-standard-font-xsmall">&nbsp; Time to Harvest: &nbsp;<span className="Text-standard-font">{`${pack.weekstoharvest}`}&nbsp;</span>weeks</p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="Text-robo-med">{`${pack.name}`}</p>
                                <div className="Green-accent" style={{marginTop: 10, marginBottom: 10}} />
                            </div>
                            <p>{`${pack.description}`}</p>
                            <div className="Preselect-included">
                                <p className="Text-cat-title-2">What's Included</p>
                                {pack.included.map((item, j) => {
                                    return (
                                        <p key={`${i}${j}included`}>{`${item.qty} - ${item.name}`}</p>
                                    )
                                })}
                            </div>
                            <div className="Preselect-bottom-row">
                                <div className="Preselect-bottom-row-div Preselect-bottom-row-div-1">
                                    <p className="pr-3">{`$${19}`}</p>
                                    <DropdownSelect
                                        size="Customize-qty-dropdown"
                                        name={pack}
                                        placeholder="Qty"
                                        optionsKey="packageOptions"
                                        value={myPkg[pack.name]? myPkg[pack.name].qty : null}
                                        onChange={(id, input) => this.props.seedStore.addPackage(id, input)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    };

    render() {
        document.title = 'Select Gropod Packages - GroPod'
        return (
            <div>
                <div className="Account-title-div" style={{marginBottom: 43}}>
                    <h2 className="Text-title-large">Pre-Select Gropod&trade; Subscriptions</h2>
                </div>
                <div className="PreSelect-row">
                    <div className="PreSelect-left">
                        {this.renderPackages()}
                    </div>
                    <div className="PreSelect-right">
                        <SubscriptionPackage/>
                    </div>
                </div>
            </div>
        );
    }
}

export default PreSelect;
