import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ViewDetailsWidget from "../../../components/ViewDetailsWidget";
import AccountWidget from "../../../components/forms/accountWidget/Index";

import { inject, observer } from "mobx-react";
import { ButtonGreen, ButtonGrey } from "../../../components/Buttons";
import { injectStripe } from "react-stripe-elements";

@inject('userStore', 'formStore', 'cartStore')
@withRouter
@observer
class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedState: null,
            edit: false
        };
    }

    async onSubmit(e) {
        e.preventDefault();
        if (this.props.formStore.useSavedToken) {
            await this.props.formStore.onUpdateMyAccount(e, this.props.history);

            this.updateUserInfo()

        } else {
            const token = await this.saveToken();
            if (token) {
                await this.props.formStore.onUpdateMyAccount(e, this.props.history);

                this.updateUserInfo()

            }
        }
    }

    async updateUserInfo() {
        if (!this.props.formStore.errors) {
            this.props.userStore.getUser();
            this.props.cartStore.getCart();
        }
    }

    async saveToken() {
        const { token } = await this.props.stripe.createToken({
            name: this.props.formStore.form.nameOnCard,
            // eslint-disable-next-line
            address_zip: this.props.formStore.form.paymentZip
        });

        if (token) {
            this.props.formStore.setPaymentToken(token);
        }

        return token;
    }

    render() {
        const submitButton = <ButtonGreen label="Save" onClick={(e) => this.onSubmit(e)} />;
        const cancelButton = <ButtonGrey label="Cancel" onClick={() => { this.setState({ edit: false }) }} />;

        return (
            <div>
                {this.state.edit ?
                    <div>
                        <AccountWidget
                            Contact
                            Shipping
                            Card
                            Billing
                            AccountPage
                            buttonTop
                            newPassword
                            updatePayment={true}
                        />
                        <div className="row align-items-center">
                            <div className="col-auto">{submitButton}</div>
                            <div className="col-auto pl-0">{cancelButton}</div>
                        </div>
                    </div>
                    :
                    <ViewDetailsWidget
                        Contact Shipping Card Billing AccountPage
                        onEditClick={() => this.setState({ edit: true })} />
                }
            </div>
        );
    }
}

export default injectStripe(Account);
