import {observable, action} from 'mobx';
import seedStore from './SeedStore';

class DropdownStore {
    @observable width = window.innerWidth;
    @observable height = window.innerHeight;
    @observable tooMany = false;
    @observable tooFew = false;

    @action updateWindowDimensions() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }

    @action toggleTooMany() {
        this.tooMany = !this.tooMany;
    }

    @action toggleTooFew() {
        this.tooFew = !this.tooFew;
    }
    
    @action goToCheckout(history) {
        if(seedStore.activeSubTab === 1) {
            if(seedStore.filledSeeds > seedStore.mySize.qty) {
                this.toggleTooMany();
            } else if(seedStore.filledSeeds < seedStore.mySize.qty) {
                history.push("/gropods/selectseeds/customize");
                seedStore.setActiveSubTab(2);
            } else {
                history.push("/gropods/checkout");
                seedStore.setActiveTab(3);
            }
        } else {
            if(seedStore.filledSeeds > seedStore.mySize.qty) {
                this.toggleTooMany();
            } else if (seedStore.filledSeeds < seedStore.mySize.qty) {
                this.toggleTooFew();
            } else {
                history.push("/gropods/checkout");
                seedStore.setActiveTab(3);
            }
        }
    }
}

export default new DropdownStore();
