import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PreSelect from "./PreSelect";
import Customize from "./Customize";
import SubTabs from "./SubTabs";

export default class SelectSeeds extends Component {
    render() {
        document.title = 'Gropod Subscription - GroPod'
        return (
            <div className="">
                <SubTabs/>
                <Route path="/gropods/selectseeds" exact render={() => <PreSelect width={this.props.width} />} />
                <Route path="/gropods/selectseeds/customize" render={() => <Customize width={this.props.width} />} />
            </div>
        );
    }
}
