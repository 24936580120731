import React, { Component } from 'react';
import ForgotPasswordForm from "../../components/forms/accountWidget/ForgotPassword";

import { inject, observer } from "mobx-react";

@inject('formStore')
@observer
class ForgotPassword extends Component {

    render() {
        return (
            <div className="Content">
                <div className="Help-contents">
                    <p className="Text-robo-med Help-category-title mb-4">Forgot Password</p>
                    <ForgotPasswordForm
                        buttonLabel="Send"
                        onClick={(e) => this.props.formStore.onForgotPassword(e)} />
                </div>
            </div>
        );
    }
}

export default ForgotPassword;

