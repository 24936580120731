import React, {Component} from 'react';
import {inject, observer} from "mobx-react";

@inject('formStore')
@observer
class Message extends Component {
    render() {
        return (
            <div>
                <textarea
                    rows="10"
                    name="message"
                    className="form-control Help-text-area"
                    onChange={(input)=>this.props.formStore.onChangeInput(input)}
                    value={this.props.formStore.form.message}
                    placeholder="Leave a Message">
                </textarea>
            </div>
        );
    }
}

export default Message;
