import React, {Component} from 'react';
import Check from "./forms/Checkbox";

export default class Header extends Component {
    render() {
        return (
            <div className={"row my-3 " + (this.props.className || "align-items-center")}>
                {this.props.children}
            </div>
        );
    }
}

export class Title extends Component {
    render() {
        if (!this.props.hide) {
            return (
                <div className={this.props.colClass || 'col-auto pr-0'}>
                    <p className={this.props.textClass || "Text-cat-title-2"}>
                        {this.props.text}
                    </p>
                </div>
            );
        } else return null
    }
}

export class Subtitle extends Component {
    render() {
        if (!this.props.hide) {
            return (
                <div className={this.props.colClass || 'col-auto pr-0'}>
                    <p className={this.props.textClass || "Text-standard-font"}>
                        {`|\u00A0 ${this.props.text}`}
                    </p>
                </div>
            );
        } else return null
    }
}

export class Checkbox extends Component {
    render() {
        if (!this.props.hide) {
            return (
                <React.Fragment>
                    <Subtitle text={this.props.text}/>
                    <div className={this.props.colClass || 'col-auto pr-0'}>
                        <Check name={this.props.name} checked={this.props.checked} onChange={this.props.onChange}/>
                    </div>
                </React.Fragment>
            );
        } else return null
    }
}

export class ContentRight extends Component {
    render() {
        const renderItem1 = <div className="col-auto ml-auto">{this.props.item1}</div>;
        const renderItem2 = <div className="col-auto pl-0">{this.props.item2}</div>;
        
        if (!this.props.hide) {
            return (
                <React.Fragment>
                    {this.props.item1 && renderItem1}
                    {this.props.item2 && renderItem2}
                </React.Fragment>
            );
        } else return null
    }
}
