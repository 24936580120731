import { observable, action } from 'mobx';

class AlertStore {
    @observable visible = false;
    @observable message = {};

    @action setMessage(message) {
        if(message.text) {
            this.message = {
                type: message.type,
                text: message.text
            }
        } else {
            if(message.type === "danger") {
                this.message = {
                    type: 'danger',
                    text: 'Error: Your action could not be completed.'
                }
            } else {
                this.message = {
                    type: 'success',
                    text: 'Your changes have been saved.'
                }
            }
        }
        this.visible = true;
        window.setTimeout(()=>{
            this.visible = false;
        },3000)
    }

    @action dismiss() {
        this.visible = false;
    }
}

export default new AlertStore();
