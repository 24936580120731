import React, { Component } from 'react';
import { withRouter, Redirect } from "react-router-dom";
import queryString from 'query-string';
import ResetPasswordForm from "../../components/forms/accountWidget/ResetPassword";

import { inject, observer } from "mobx-react";

@inject('formStore')
@withRouter
@observer
class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: '',
            userId: ''
        }
    }

    componentDidMount = () => {
        let params = queryString.parse(this.props.location.search);
        if (params && params.token) {
            this.setState({
                token: params.token,
                userId: params.userId
            })
        }
    }

    render() {
        console.log('State Token->>>', this.state)
        const queryInfo = queryString.parse(this.props.location.search);

        return (
            <div className="Content">
                {
                    (queryInfo && queryInfo.token) ?
                        <div className="Help-contents">
                            <p className="Text-robo-med Help-category-title mb-4">Reset Password</p>
                            <ResetPasswordForm
                                buttonLabel="Send"
                                onClick={(e) => this.props.formStore.onResetPassword(e, this.state, this.props.history)} />
                        </div>
                        :
                        <Redirect to={{ pathname: "/login" }} />
                }
            </div>
        );
    }
}

export default ResetPassword;

