import React, {Component} from 'react';
import DropdownSelect from "./forms/DropdownSelect";
import {inject, observer} from "mobx-react";

@inject('seedStore', 'appStore')
@observer
class FooterPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newSize: null,
            name: "mySize",
            input: null,
        };
    }
    
    componentDidMount() {
        this.setState({cachedSize: this.props.seedStore.mySize.qty})
    }

    onChange(name, input) {
        this.setState({name: name, input: input, newSize: input})
    }

    onSave() {
        const input = this.state.input? this.state.input: this.props.seedStore.selectedSize;
        this.props.seedStore.onChangeSize(this.state.name, input);
        this.props.seedStore.hideFooterPanel();
        this.setState({input: null, newSize: null})
    }

    onClose() {
        this.props.seedStore.hideFooterPanel();
        this.setState({input: null, newSize: null})
    }
    
    render() {
        const newSize = this.state.newSize;
        const selectedSize = this.props.seedStore.selectedSize;

        return (
            <div className={this.props.seedStore.closeFooterPanelCountdown? "d-block footer-panel" : "d-none"}>
                <div className="btn-close-container">
                    <button className="btn btn-link btn-close" onClick={this.onClose.bind(this)}>&#10005;</button>
                </div>
                <div className="row align-items-end">
                    <div className="col-12 col-lg-8 mb-3">
                        <h4 className="mb-2">Need more G-Pods?</h4>
                        <p>No problem, we've updated your order to {this.props.seedStore.mySize.qty} pods.</p>
                        <p>To customize your subscription, select a new size from the dropdown menu.</p>
                    </div>
                    <div className="col-9 col-md-6 col-lg-3 mb-3">
                        <DropdownSelect
                            size="w-100"
                            name="mySize"
                            placeholder="Select Size"
                            optionsKey="sizeOptions"
                            value={newSize? newSize : selectedSize}
                            onChange={(name, input)=> this.onChange(name, input)}
                            menuPlacement={"top"}
                        />
                    </div>
                    <div className="col-3 col-md-2 col-lg-1 mb-3">
                        <button className="btn btn-success" onClick={this.onSave.bind(this)}>Save</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FooterPanel;
