import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import { Route } from 'react-router-dom';
import ScrollToTop from "../../components/ScrollToTop";
import OrderSummary from "./OrderSummary";
import OrderSuccess from "./OrderSummary/OrderSuccess";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import PageTitle from "../../components/PageTitle";
import NavTabs from "../../components/NavTabs";

@inject('cartStore')
@observer
class Checkout extends Component {
    render() {
        return (
            <ScrollToTop>
                <div className="Page-content">
                    <PageTitle
                        title="Checkout"
                        subtitle=""
                    />
                    <NavTabs
                        store="cartStore"
                        tab1={{name: "Shipping Address", route: "/checkout"}}
                        tab2={{name: "Payment Method", route: "/checkout/paymentmethod"}}
                        tab3={{name: "Order Summary", route: "/checkout/ordersummary"}}
                    />
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Route path="/checkout/" exact component={ShippingAddress} />
                        <Route path="/checkout/paymentmethod" exact component={PaymentMethod} />
                        <Route path="/checkout/ordersummary" exact component={OrderSummary} />
                        <Route path="/checkout/ordersuccess" exact component={OrderSuccess} />
                    </div>
                </div>
            </ScrollToTop>
        );
    }
}

export default Checkout;
