import React, { Component } from 'react';
import AccountWidget from "../../../components/forms/accountWidget/Index";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {injectStripe} from "react-stripe-elements";

@inject('cartStore', 'formStore', 'alertStore')
@withRouter
@observer
class PaymentMethod extends Component {

    async nextPage(e) {
        e.preventDefault();
        if(this.props.formStore.useSavedToken) {
            this.props.cartStore.setActiveTab(3);
            this.props.history.push('/checkout/ordersummary');
            
        } else {
            const token = await this.saveToken();
            if(token) {
                this.props.cartStore.setActiveTab(3);
                this.props.history.push('/checkout/ordersummary');
                
            } else {
                this.props.alertStore.setMessage({type: 'danger', text: "Error: Please check your payment information"});
            }
        }
    };

    async saveToken() {
        const {token} = await this.props.stripe.createToken({
            name: this.props.formStore.form.nameOnCard,
            // eslint-disable-next-line
            address_zip: this.props.formStore.form.paymentZip
        });

        if (token) {
            this.props.formStore.setPaymentToken(token);
        }
        
        return token;
    }

    render() {
        return (
            <div className="Checkout-form-div">
                <AccountWidget 
                    Card 
                    Billing
                    updatePayment={true}
                    buttonLabel="Next" 
                    buttonClass="Account-save-button" 
                    onClick={(e)=>{this.nextPage(e)}} 
                />
            </div>

        );
    }
}

export default injectStripe(PaymentMethod);
