import React, {Component} from 'react';
const starGreen = require("../assets/stargreen.png");
const starGrey = require("../assets/stargrey.png");

export default class StarRating extends Component {
    
    starRatingDisplay = (rating, spacing) => {
        const starColorArray = [];
        for (let i=0; i<5; i++){
            if(i<rating){
                starColorArray.push("green");
            } else {
                starColorArray.push("grey");
            }
        }
        return starColorArray.map((color, i) => {
            return color === "green" ?
                <img alt="Green Star" key={`${i} green`} src={starGreen} style={spacing ? {marginLeft:spacing/2, marginRight:spacing/2} : null} className="Gropod-review-star" />
                :
                <img alt="Grey Star" key={i} src={starGrey} style={spacing ? {marginLeft:spacing/2, marginRight:spacing/2} : null} className="Gropod-review-star" />
        });
    };
            
    render() {
        return (
            <React.Fragment>
                {this.starRatingDisplay(this.props.rating, 5)}
            </React.Fragment>
        );
    }
}
