import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PageTitle from "../../components/PageTitle";
import ChooseAmount from "./ChooseAmount";
import SelectSeeds from "./SelectSeeds";
import Checkout from "./Checkout";
import SubscriptionSuccess from "./Checkout/SubscriptionSuccess";
import NavTabs from "../../components/NavTabs";
import {inject, observer} from "mobx-react";

@inject('subscriptionStore', 'seedStore')
@observer
class Seedpods extends Component {
    componentWillMount() {
        this.props.seedStore.getSeedCart();
    }

    render() {
        document.title = 'Subscription - GroPod'

        return (
            <div>
                <div className="Page-content">
                    <div className="SeedPod-Selection">
                        <PageTitle
                            title="Naturally Yours&trade;"
                            subtitle="The GroPod's integrated software system will automatically optimize the growing conditions based on the varieties you select."
                        />
                    </div>
                    <NavTabs
                        store="seedStore"
                        isSeedCheckout={true}
                        tab1={{name: "Choose Amount", route: "/gropods"}}
                        tab2={{name: "Select Gropods", route: "/gropods/selectseeds"}}
                        tab3={{name: "Checkout", route: "/gropods/checkout"}}
                    />
                    <div>
                        <Route path="/gropods" exact component={ ChooseAmount } />
                        <Route path="/gropods/selectseeds" component={SelectSeeds} />
                        <Route path="/gropods/checkout" component={Checkout} />
                        <Route path="/gropods/success" component={SubscriptionSuccess} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Seedpods;
