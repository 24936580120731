import React, {Component} from 'react';
import {NavLink, withRouter, matchPath} from "react-router-dom";
import {inject, observer} from "mobx-react";

@inject('seedStore')
@withRouter
@observer
class SubTabs extends Component {
    componentWillMount() {
        if(!!matchPath(this.props.location.pathname, '/gropods/selectseeds/customize')) {
            this.props.seedStore.setActiveSubTab(2);
        } else {
            this.props.seedStore.setActiveSubTab(1);
        }
    }
    
    render() {
        const activeTab = this.props.seedStore.activeSubTab;
        return (
            <div className="Seedpods-tabs-container Select-tabs-container">
                <div className="Seedpods-navLinks-div SelectSeeds-navLinks-div">
                    <NavLink
                        exact={true}
                        to='/gropods/selectseeds'
                        activeClassName="Seedpods-active-tab Select-Active-tab Select-tab-left"
                        className="Seedpod-link Select-Link"
                        style={{color: activeTab === 1 ? "white" : "rgba(58,95,11, 0.5)",}} >
                        <div className="Seedpod-tab Select-tab" onClick={() => {this.props.seedStore.setActiveSubTab(1)}}>
                            <p>Pre-Select Package</p>
                        </div>
                    </NavLink>
                    <NavLink 
                        exact={true}
                        to='/gropods/selectseeds/customize'
                        activeClassName="Seedpods-active-tab Select-Active-tab Select-tab-right"
                        className="Seedpod-link Select-Link"
                        style={{color: activeTab === 2 ? "white" : "rgba(58,95,11, 0.5)",}} >
                        <div className="Seedpod-tab Select-tab" onClick={() => {this.props.seedStore.setActiveSubTab(2)}}>
                            <p>Customize</p>
                        </div>
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default SubTabs;
