import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import Cart from "../../ShoppingCart/cartWidget/Cart";
import Checkbox from "../../../components/forms/Checkbox";
import ProductTerms from "../../../components/modals/ProductTerms";
import {ButtonGreen} from "../../../components/Buttons";
import {faPen} from "@fortawesome/free-solid-svg-icons";

const visaImg = require("../../../assets/visa.png");

@inject('cartStore', 'userStore', 'formStore')
@withRouter
@observer
class OrderSummary extends Component {
    navigateToBilling = () => {
        this.props.cartStore.setActiveTab(2);
        this.props.history.push('/checkout/paymentmethod');
    };

    navigateToShipping = () => {
        this.props.cartStore.setActiveTab(1);
        this.props.history.push('/checkout/');
    };

    showCardImg(brand) {
        if (brand === "Visa") {
            return <img src={visaImg} alt="Visa Card" className="Account-view-ccImage"/>
        }
    }

    showPayment(user, token) {
        if (this.props.formStore.useSavedToken) {
            if (this.props.userStore.authUser.savedPayment) {
                const savedCard = this.props.userStore.authUser.savedPayment;

                return (
                    <React.Fragment>
                        <p>{`${savedCard ? '**** **** **** ' + savedCard.last4 : "None"}`}</p>
                        {this.showCardImg(savedCard.type)}
                    </React.Fragment>
                )
            } else {
                return <p>None</p>
            }

        } else {
            return (
                <React.Fragment>
                    <p>{`${token ? '**** **** **** ' + token.card.last4 : "None"}`}</p>
                    {this.showCardImg(token.card.brand)}
                </React.Fragment>
            )
        }
    }

    showShipping() {
        const user = this.props.userStore.authUser.shippingAddress1? this.props.userStore.authUser: this.props.formStore.form;
        if (user.shippingAddress1) {
            return (
                <div className="Cart-billing-row-info">
                    {`${user.shippingAddress1} ${user.shippingAddress2} ${user.shippingCity}, ${user.shippingState ? user.shippingState.value : ""} ${user.shippingZip}`}
                </div>
            )
        } else {
            return (
                <div className="Cart-billing-row-info">
                    None
                </div>
            )
        }
    }

    render() {
        if (this.props.userStore.isLoading) {
            return null
        }

        const user = this.props.userStore.authUser;
        const paymentToken = this.props.formStore.form.paymentToken || null;

        return (
            <div className="w-100">
                <div className="Cart-chart" style={{marginBottom: 32}}>
                    <Cart width={this.props.width}/>
                    <div className="Cart-billing-row border-top">
                        <div className="Cart-billing-row-title">
                            <p className="Text-cat-title-2">Shipping Address</p>
                        </div>
                        {this.showShipping()}
                        <div className="Cart-billing-row-icon">
                            <FontAwesomeIcon icon={faPen} className="Cart-edit-icon" onClick={this.navigateToShipping}/>
                        </div>
                    </div>
                    <div className="Cart-billing-row">
                        <div className="Cart-billing-row-title">
                            <p className="Text-cat-title-2">{`Card Information`}</p>
                        </div>
                        <div className="Cart-billing-row-info">
                            <div className="Account-card-row Account-view-right-item">
                                {this.showPayment(user, paymentToken)}
                            </div>
                        </div>
                        <div className="Cart-billing-row-icon">
                            <FontAwesomeIcon icon={faPen} className="Cart-edit-icon" onClick={this.navigateToBilling}/>
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <Checkbox name="agreeToCheckoutTerms"/>
                    <span className="pl-3">I agree to the </span>
                    <ProductTerms/>
                </div>
                <ButtonGreen
                    className="Cart-checkout-order-button"
                    label="Place Order"
                    isDisabled={!this.props.formStore.form.agreeToCheckoutTerms || this.props.cartStore.numItemsInCart === 0}
                    onClick={() => this.props.formStore.onCheckout(this.props.history)}
                />
            </div>
        );
    }
}

export default OrderSummary;
